// **  React Imports
import React, { useState, useEffect } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'

// ** Icons Imports
import ChevronDown from 'mdi-material-ui/ChevronDown'
import ChevronUp from 'mdi-material-ui/ChevronUp'
import AccountIcon from 'mdi-material-ui/AccountCircle'
import EmailIcon from 'mdi-material-ui/Email'
import PaymentIcon from '@mui/icons-material/Payment'

// ** Custom Components
import CustomAvatar from 'core/components/mui/avatar'
import DeleteContactModal from '../modals/DeleteContactModal'
import AlertMessages from 'pages/Billing/components/AlertMessages'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { customersSelector, manageContacts } from 'store/customers'

const ContactCard = props => {
  // ** Props
  const { contact } = props

  // ** Hooks
  const dispatch = useDispatch()

  // ** State
  const [collapsed, setCollapsed] = useState(null)
  const [fields, setFields] = useState([]) // State holding the fields to be edited when clicking on property
  const [updatedContact, setUpdatedContact] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    email: ''
  })
  // State holding the first contact
  const [firstContact, setFirstConact] = useState(null)

  // ** Selectors
  const { customerInformation, success, error, loading } = useSelector(customersSelector)

  // ** Fill contact informations from the first created contact in user's contacts
  useEffect(() => {
    if (customerInformation != undefined) {
      if (customerInformation?.contacts?.length > 0)
        setFirstConact(
          customerInformation?.contacts?.reduce((oldest, current) =>
            current.createdAt < oldest.createdAt ? current : oldest
          )
        )
    }
  }, [customerInformation])

  // ** Functions
  const collapseHandler = id => {
    if (id == collapsed) setCollapsed(null)
    else setCollapsed(id)
  }

  // ** Function used to initialize the updatedContact state with the current contact info
  const informationHandler = contact => {
    setUpdatedContact({ ...updatedContact, ...contact })
  }
  const intializeInformationHandler = () => {
    setUpdatedContact({
      firstname: '',
      lastname: '',
      phone: '',
      email: ''
    })
  }
  // ** Watch information changes
  const inputHandler = e => {
    const { name, value, checked } = e.target

    if (name === 'toBeNotified') {
      setFields([...fields, 'toBeNotified'])
      setUpdatedContact({ ...updatedContact, toBeNotified: checked })
    } else if (name === 'isBillingContact') {
      setFields([...fields, 'isBillingContact'])
      setUpdatedContact({ ...updatedContact, isBillingContact: checked })
    } else setUpdatedContact({ ...updatedContact, [name]: value })
  }

  const submitUpdateHandler = () => {
    const { updatedAt, createdAt, customerId, ...rest } = updatedContact
    dispatch(manageContacts({ id: customerId, contacts: [rest] }))
  }

  return (
    <Grid item xs={12} sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[400]}`, px: 5, width: '100%' }}>
      <Card
        sx={{
          borderRadius: 0,
          boxShadow: 0,
          borderBottom: theme => `solid 1px ${theme.palette.grey[100]}`,
          mx: '-1.25rem'
        }}
      >
        <CardHeader
          sx={{ py: '0.5rem !important' }}
          title={
            <>
              {!(collapsed == contact.id) ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CustomAvatar
                    sx={{
                      mr: 3,
                      width: '3rem',
                      height: '3rem',
                      backgroundColor: 'rgb(224, 224, 224)',
                      color: theme => theme.palette.grey[500]
                    }}
                  />
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ mx: 1, fontSize: '0.95rem', cursor: 'pointer' }}>
                        {contact?.firstname + ' ' + contact.lastname}
                      </Typography>
                      <Typography sx={{ color: 'text.secondary', fontSize: '0.825rem', mx: 1 }}>
                        {contact?.email}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ color: 'text.secondary', fontSize: '0.825rem', mx: 1 }}>
                        Email preferences :
                      </Typography>
                      <Typography sx={{ color: 'text.secondary', mx: 1, fontSize: '0.825rem' }}>
                        {contact?.toBeNotified ? 'All payments email' : 'No payments email'}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ color: 'text.secondary', mx: 1, fontSize: '0.95rem' }}>
                        {contact?.phone}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Typography sx={{ fontSize: '0.925rem', fontWeight: 600 }}>User info</Typography>
              )}
            </>
          }
          action={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {firstContact?.id !== contact.id ? <DeleteContactModal contact={contact} /> : null}
              <IconButton
                size='small'
                aria-label='collapse'
                sx={{ mr: 2, my: '30%', color: 'text.secondary' }}
                onClick={() => collapseHandler(contact.id)}
              >
                {!(collapsed == contact.id) ? (
                  <ChevronDown onClick={() => informationHandler(contact)} fontSize='medium' />
                ) : (
                  <ChevronUp onClick={intializeInformationHandler} fontSize='medium' />
                )}
              </IconButton>
            </Box>
          }
        />
        <Collapse
          in={collapsed == contact.id ? true : false}
          sx={{
            transition: 'border-bottom 0.1s ease-in-out, box-shadow .1s ease-in-out'
          }}
        >
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Stack direction='row' alignItems='center' gap={6}>
                  <AccountIcon sx={{ color: 'text.secondary' }} />
                  <Typography>Contact details</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sx={{ pl: '3rem', mt: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                {fields.includes('fullname') ? (
                  <Stack direction='row' alignItems='center' gap={4}>
                    <TextField
                      label='First name'
                      variant='standard'
                      size='small'
                      name='firstname'
                      onChange={inputHandler}
                      value={updatedContact.firstname}
                      InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                      InputProps={{ style: { height: 25, fontSize: '0.875rem' } }}
                    />
                    <TextField
                      label='Last name'
                      variant='standard'
                      size='small'
                      name='lastname'
                      value={updatedContact.lastname ?? ''}
                      onChange={inputHandler}
                      InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                      InputProps={{ style: { height: 25, fontSize: '0.875rem' } }}
                    />
                  </Stack>
                ) : null}
                {!fields.includes('fullname') && (
                  <Typography
                    sx={{ color: 'text.secondary', fontSize: '0.925rem', my: 1, cursor: 'pointer' }}
                    onClick={() => setFields([...fields, 'fullname'])}
                  >
                    {contact?.firstname + ' ' + contact.lastname}
                  </Typography>
                )}
                {fields.includes('email') ? (
                  <TextField
                    label='Email'
                    type='email'
                    variant='standard'
                    size='small'
                    name='email'
                    value={updatedContact.email ?? ''}
                    onChange={inputHandler}
                    sx={{ my: 2 }}
                    InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                    InputProps={{ style: { height: 25, fontSize: '0.875rem', width: '50%' } }}
                  />
                ) : null}
                {!fields.includes('email') && (
                  <Typography
                    sx={{ color: 'text.secondary', fontSize: '0.925rem', my: 1, cursor: 'pointer' }}
                    onClick={() => setFields([...fields, 'email'])}
                  >
                    {contact?.email}
                  </Typography>
                )}
                {fields.includes('phone') || !contact?.phone ? (
                  <TextField
                    label='Phone'
                    type='tel'
                    variant='standard'
                    size='small'
                    name='phone'
                    value={updatedContact.phone ?? ''}
                    onChange={inputHandler}
                    sx={{ my: 2 }}
                    InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                    InputProps={{ style: { height: 25, fontSize: '0.875rem', width: '50%' } }}
                  />
                ) : null}
                {!fields.includes('phone') && (
                  <Typography
                    sx={{ color: 'text.secondary', fontSize: '0.925rem', my: 1, cursor: 'pointer' }}
                    onClick={() => setFields([...fields, 'phone'])}
                  >
                    {contact?.phone}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mt: '0.5rem' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Stack direction='row' alignItems='center' gap={6}>
                    <EmailIcon sx={{ color: 'text.secondary' }} />
                    <Typography>Email preferences</Typography>
                  </Stack>
                  <Stack direction='row' alignItems='center'>
                    <Checkbox
                      defaultChecked={contact?.toBeNotified}
                      sx={{ ml: '2.25rem' }}
                      name='toBeNotified'
                      onChange={inputHandler}
                    />
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ mt: '0.5rem', display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Stack direction='row' alignItems='center' gap={6}>
                    <PaymentIcon sx={{ color: 'text.secondary' }} />
                    <Typography>Billing Contact</Typography>
                  </Stack>
                  <Stack direction='row' alignItems='center'>
                    <Checkbox
                      defaultChecked={contact?.isBillingContact}
                      sx={{ ml: '2.25rem' }}
                      name='isBillingContact'
                      onChange={inputHandler}
                    />
                  </Stack>
                </Box>

                <Button
                  startIcon={
                    loading == 'UPDATE_CONTACT' && <CircularProgress sx={{ color: 'common.white' }} size='1rem' />
                  }
                  disabled={updatedContact.id == undefined || fields.length < 1}
                  sx={{
                    height: 35,
                    mt: '0.25rem',
                    '&:disabled': { backgroundColor: theme => theme.palette.grey[600] }
                  }}
                  color='primary'
                  variant='contained'
                  size='small'
                  onClick={submitUpdateHandler}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
      <AlertMessages
        success={success}
        error={error === 'UPDATE_INFO'}
        messageValue={success == 'UPDATE' && 'UPDATE'}
        message={success ? 'Action done successfully !' : 'An error has occured please try again !'}
      />
    </Grid>
  )
}

export default ContactCard
