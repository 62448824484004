import axios from 'axios'
import { cloudGet, del, get, post, put, uploadFile } from './api_setup'
import * as url from './url_helper'

// ** Authentication
const getAdminAccess = data => post(url.POST_GET_ACCESS, data)
const getAdminAppAccess = data => post(url.GET_APP_ACCESS, data)
const refreshFreshbooksToken = () => get(url.POST_REFRESH_FRESHBOOK_TOKEN)

// ** Customers
const getOnboardingCustomers = ({ limit = 20, page = 1, status, field } = { limit: 20, page: 1, status: 'pending' }) =>
  get(url.GET_ONBOARDING_CUSTOMERS + `?page=${page}&limit=${limit}&status=${status}&field=${field}`)
const getBoardedCustomersApi = ({
  billingDay = '',
  organization = '',
  freshbooksId = '',
  page = 1,
  limit = 10000
} = {}) =>
  get(
    url.GET_CUSTOMERS +
      `?page=${page}&limit=${limit}` +
      `${billingDay ? '&billingDay=' + billingDay : ''}` +
      `${organization ? '&organization=' + organization : ''}` +
      `${freshbooksId ? '&freshbooksId=' + freshbooksId : ''}`
  )
const getCustomersBillingApi = billingDay =>
  axios.post(process.env.REACT_APP_BASEURL + url.GET_CUSTOMERS_TO_BILL, billingDay).then(response => response)
const multipleCustomersBill = data => post(url.POST_CUSTOMERS_MULTIPLE_BILL, data)
const getGoogleCustomer = id => get(url.GET_GOOGLE_CUSTOMER + id)
const fetchCustomerDailyUsages = (id, filters) => post(url.GET_CUSTOMER_DAILY_CHANGES + id, filters)
const getBoardedCustomerApi = id => get(url.GET_BOARDED_CUSTOMER + id)
const getGoogleCustomerContact = id => get(url.GET_CUSTOMER_CONTACTS + id)
const getCustomerByFreshbooksId = id => get(url.GET_CUSTOMER_BY_FRESHBOOKS + id)
const createCustomerApi = data => post(url.GET_CUSTOMERS, data)
const updateCustomerApi = (id, data) => put(url.GET_CUSTOMERS + '/' + id, data)
const updateGoogleCustomerApi = (id, data) => put(url.GET_GOOGLE_CUSTOMER + id, data)
const synchronizeCustomers = () => get(url.GET_SYNCHRONIZE_CUSTOMERS)
const exportCustomers = () => get(url.GET_EXPORT_CUSTOMERS)
const exportSubscriptions = () => get(url.GET_EXPORT_SUBSCRIPTIONS)
const seedCustomerSubscriptions = params => get(url.QUERY_SEED_SUBSCRIPTIONS + params)
const createBulkPricingBookSUbscription = data => post(url.POST_BULK_PRICING_BOOK_SUBSCRIPTION, data)
const updateInvoiceFreshbooks = params => get(url.GET_UPDATE_INVOICE + params)
const sendInvoiceByEmailFreshbooksApi = ({ freshbooksInvoiceId, email }) =>
  post(url.POST_SEND_INVOICE_FRESHBOOKS_EMAIL, { freshbooksInvoiceId, email })
const regenrateInvoice = data => post(url.POST_REGENERATE_INVOICE_FRESHBOOKS, data)
const exportCustomersToSheet = () => get(url.GET_EXPORT_CUSTOMERS_SHEET)
const splitCustomerGoogleId = data => post(url.PUT_CUSTOMER_GOOGLE_ID, data)
const deleteCustomer = id => del(url.GET_CUSTOMERS + '/' + id)

// ** Subscriptions
const getCustomerSubscriptions = id => get(url.GET_CUSTOMER_SUBSCRIPTIONS + id)
const getAllProducts = ({ limit = 20, page = 1, field } = { limit: 20, page: 1 }) =>
  get(url.PRODUCTS + `?page=${page}&limit=${limit}&field=${field}`)
const getProduct = id => get(url.PRODUCTS + id)
const createProduct = product => post(url.PRODUCTS, product)
const updateProduct = (id, product) => put(url.PRODUCTS + id, product)
const removeProduct = id => del(url.PRODUCTS + id)
const recalculateDailyUsage = data => post(url.POST_RECALCULATIONS, data)
const createNonGoogleSkuSubscription = subscription => post(url.POST_CREATE_NON_GOOGLE_SKU_SUBSCRIPTION, subscription)
const updateNonGoogleSkuSubscriptionApi = (id, subscription) =>
  put(url.POST_CREATE_NON_GOOGLE_SKU_SUBSCRIPTION + id, subscription)
const createDailyUsage = data => post(url.POST_CREATE_DAILY_USAGE, data)
const createDailyChange = data => post(url.POST_CREATE_DAILY_CHANGES, data)
const updateCustomerSubscription = (id, data) => put(url.PUT_SUBSCRIPTIONS + id, data)
const exportSkusToSheet = () => get(url.GET_EXPORT_SKUS_SHEET)

// ** Freshbooks
const getFreshbooksSuggestions = oragnizationName => get(url.GET_FRESHBOOKS_SUGGESTIONS + oragnizationName)
const createFreshbooksId = data => post(url.CREATE_NEW_FRESHBOOKS, data)

// ** METADATA
const getMetaDataData = type => get(url.CRUD_METDATA + type)
const getTaxData = () => get(url.GET_TAXDATA)
const createMetadata = data => post(url.CRUD_METDATA, data)
const editMetadata = (id, data) => put(url.CRUD_METDATA + id, data)
const getAllPricingBooks = ({ page = 1, limit = 1000, status = '' } = { limit: 100, page: 1, status: '' }) =>
  get(url.GET_PRICING_BOOKS + `?page=${page}&limit=${limit}&status=${status}`)
const createPricingBook = data => post(url.POST_PRICING_BOOKS, data)
const updatePricingBookApi = (id, data) => put(url.PUT_PRICING_BOOKS + id, data)
const updateDefaultPricingBook = data => post(url.PUT_DEFAULT_PRICING, data)
const updateBulkDefaultPricingBook = data => post(url.PUT_CUSTOMERS_PRICING_BULK, data)

// ** Invoices
const checkBilledDays = params => cloudGet(url.GET_CHECK_BILLED_DAYS + params)
const getInvoices = params => cloudGet(url.GET_CUSTOMER_INVOICES + params)
const getInvoice = params => cloudGet(url.GET_CUSTOMER_INVOICE + params)
const generateInvoiceApi = params => cloudGet(url.GENERATE_INVOICE + params)
const generateMonthlyUsage = data => post(url.POST_CREATE_MONTHLY_USAGE, data)
const uploadGoogleUsage = data => post(url.UPLOAD_GOOGLE_USAGE, data)
const getDailyUsageInvoice = id => get(url.GET_DAILYUSAGE_INVOICE + id)
const getGcpUsage = invoiceId => get(url.GET_GCP_USAGE + invoiceId)
const fetchCustomerGoogleUsage = ({ id, from, to }) =>
  get(url.GET_CUSTOMER_GOOGLE_USAGE + `?customerId=${id}&from=${from}&to=${to}`)
const getCustomerInvoicesHistory = ({ id, from, to }) =>
  get(url.GET_CUSTOMER_INVOICES_HISTORY + `?from=${from}&to=${to}&customerId=${id}`)
const getCustomerMonthConsumption = ({ from, to, customerId, type = 'rear' }) =>
  cloudGet(url.GET_CUSTOMER_MONTH_CONSUMPTION + `?from=${from}&to=${to}&type=${type}&customerId=${customerId}`)
const getUsagesToCompare = ({ from, to, page = 1, limit = 20, billingType = '', billingCycle = '', search = '' }) =>
  get(
    url.GET_COMPARE_USAGES +
      `?page=${page}&limit=${limit}&from=${from}&to=${to}&billingType=${billingType}&billingCycle=${billingCycle}&organization=${search}`
  )
const getPricesFresbooksToCompare = ({
  from,
  to,
  page = 1,
  limit = 20,
  billingType = '',
  billingCycle = '',
  search = ''
}) =>
  get(
    url.GET_COMPARE_FRESHBOOK_PRICE +
      `?page=${page}&limit=${limit}&from=${from}&to=${to}&billingType=${
        billingType || ''
      }&billingCycle=${billingCycle}&organization=${search}`
  )

const getCheckDailyUsages = ({ from, to, page = 1, limit = 20 }) =>
  get(url.GET_CHECK_DAILYUSAGES + `?page=${page}&limit=${limit}&from=${from}&to=${to}`)

// ** Rules
const fetchRulesData = () => get(url.GET_RULES)
const fetchAdminsGroup = () => get(url.GET_ADMINS_GROUP)
const addRule = data => post(url.CRUD_RULES, data)
const fetchRule = id => get(url.GET_RULES + id)
const updateRule = (id, data) => put(url.CRUD_RULES + id, data)
const removeRule = id => del(url.GET_RULES + id)
const fetchSalesAgent = () => get(url.GET_SALES_AGENT)
const fetchOpportunitiesData = (salesAgentId, status) =>
  get(
    url.GET_OPPORTUNITIES +
      `${salesAgentId ? '?salesAgentId=' + salesAgentId : ''}${
        status ? (salesAgentId ? '&status=' + status : '?status=' + status) : ''
      }`
  )
const fetchAgentDealsData = (salesAgentId, from, to) =>
  get(url.GET_OPPORTUNITIES + '?salesAgentId=' + salesAgentId + '&from=' + from + '&to=' + to)

// ** Invoice Template
const addInvoiceTemplate = data => post(url.CRUD_INVOICE_TEMPALTE, data)
const getInvoiceTemplates = customerId => get(url.CRUD_INVOICE_TEMPALTE + '?customerId=' + customerId)
const updateInvoiceTemplate = (id, data) => put(url.CRUD_INVOICE_TEMPALTE + id, data)
const deleteInvoiceTemplate = id => del(url.CRUD_INVOICE_TEMPALTE + id)

// ** Payouts
const getAllPayouts = (from, to) =>
  get(url.GET_ALL_PAYOUTS + `${from ? '?from=' + from : ''}${to ? (from ? '&to=' + to : '?to=' + to) : ''}`)
const payAgents = data => post(url.POST_PAY_PAYOUT, data)

export {
  exportSkusToSheet,
  fetchAgentDealsData,
  payAgents,
  getAllPayouts,
  deleteInvoiceTemplate,
  updateInvoiceTemplate,
  getInvoiceTemplates,
  fetchAdminsGroup,
  addInvoiceTemplate,
  fetchOpportunitiesData,
  fetchSalesAgent,
  fetchRule,
  fetchRulesData,
  addRule,
  updateRule,
  removeRule,
  getTaxData,
  getBoardedCustomersApi,
  getGoogleCustomer,
  getGoogleCustomerContact,
  getOnboardingCustomers,
  getCustomerSubscriptions,
  getBoardedCustomerApi,
  getCustomerByFreshbooksId,
  sendInvoiceByEmailFreshbooksApi,
  createCustomerApi,
  updateCustomerApi,
  updateGoogleCustomerApi,
  getFreshbooksSuggestions,
  createFreshbooksId,
  getAllProducts,
  createProduct,
  updateProduct,
  getProduct,
  removeProduct,
  recalculateDailyUsage,
  synchronizeCustomers,
  exportCustomers,
  exportSubscriptions,
  seedCustomerSubscriptions,
  getAllPricingBooks,
  createMetadata,
  editMetadata,
  createPricingBook,
  updatePricingBookApi,
  updateDefaultPricingBook,
  updateBulkDefaultPricingBook,
  createBulkPricingBookSUbscription,
  checkBilledDays,
  getInvoices,
  getInvoice,
  updateInvoiceFreshbooks,
  generateInvoiceApi,
  regenrateInvoice,
  getMetaDataData,
  getAdminAccess,
  getAdminAppAccess,
  refreshFreshbooksToken,
  createNonGoogleSkuSubscription,
  createDailyUsage,
  createDailyChange,
  updateNonGoogleSkuSubscriptionApi,
  fetchCustomerDailyUsages,
  getCustomersBillingApi,
  multipleCustomersBill,
  generateMonthlyUsage,
  getDailyUsageInvoice,
  exportCustomersToSheet,
  splitCustomerGoogleId,
  fetchCustomerGoogleUsage,
  getCustomerInvoicesHistory,
  uploadGoogleUsage,
  getCustomerMonthConsumption,
  getUsagesToCompare,
  deleteCustomer,
  getCheckDailyUsages,
  updateCustomerSubscription,
  getGcpUsage,
  getPricesFresbooksToCompare
}
