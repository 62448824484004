// ** React Imports
import React from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { customersSelector } from 'store/customers'

// ** MUI Imports
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

// ** 3rd Party Libraries
import moment from 'moment'
import { currencySelector } from 'store/metaData/currency'

const TransactionsTableRow = props => {
  // ** Props
  const { row, type } = props

  // ** Selectors
  const { customerInformation, invoiceInformation } = useSelector(customersSelector)
  const { currenciesData } = useSelector(currencySelector)

  // ** Constants
  const currency = customerInformation?.currency?.id
    ? customerInformation?.currency
    : currenciesData?.find(item => item.iso_code === 'CAD')

  // Calculate single row
  const calculateSingleRow = (prices, total, row) => {
    const customerCurrencyId = currency?.id
    const currencySymbol = currency?.iso_code === 'EUR' ? '€' : '$'
    const price = prices?.find(item => item.currencyId === customerCurrencyId)?.totalValue ?? 0
    if (type === 'advance')
      return total < 0 ? '-' + currencySymbol + total?.toFixed(2) : currencySymbol + total?.toFixed(2)
    if (price < 0) return '-' + currencySymbol + ((price || 0) * -1)?.toFixed(2)
    else return currencySymbol + (price || 0)?.toFixed(2)
  }

  // Find domain
  const findDomain = id => {
    const domain =
      customerInformation?.googleIds?.find(el => el.subscriptions?.some(sub => sub.subscriptionId === id))?.domain ??
      customerInformation?.domain
    return domain
  }

  // Format date
  const formatDate = intervals => {
    if (type === 'advance') {
      const start = moment(intervals?.[intervals?.length - 1]?.from)
      const end = moment(intervals?.[0]?.to)
      return [moment(start).format('MMM DD, YYYY'), moment(end).format('MMM DD, YYYY')]
    }
    return intervals.map(period => {
      const start = moment(period.from)
      const end = moment(period.to)
      if (start.format('D') == end.format('D') && start.format('M') == end.format('M')) {
        return moment(start).format('MMM DD, YYYY')
      } else if (start.format('D') != end.format('D') && start.format('M') == end.format('M')) {
        return moment(start).format('MMM DD') + ' - ' + moment(end).format('DD, YYYY')
      } else if (start.format('M') != end.format('M')) {
        return moment(start).format('MMM DD') + ' - ' + moment(end).format('MMM DD, YYYY')
      } else return ''
    })
  }

  // Format discount date
  const formatDiscountDate = (discount, skuId, licenceType, discounts) => {
    const totalDiscounts = discounts?.reduce((acc, { value }) => acc + parseFloat(value || 0), 0)?.toFixed(2)
    let subscriptionPrice = null
    let period = null
    const licenceTypeFormatted = licenceType === 'FREE' ? '' : licenceType === 'FLEXIBLE' ? 'flexible' : 'annual'
    if (!!skuId) {
      customerInformation?.googleIds?.forEach(googleId => {
        const subscription = googleId?.subscriptions?.find(sub => sub?.subscriptionId === skuId) ?? null
        if (!!subscription) {
          const customerPricingBook = customerInformation?.subscriptionPricingBooks?.find(
            item => item.subscriptionId === subscription?.subscriptionId
          )
          if (!!customerPricingBook?.pricingBookId) {
            const subscriptionEntity = subscription?.product?.prices?.find(
              item =>
                item.currencyId === currency?.id &&
                item.type === licenceTypeFormatted &&
                item.pricingBookId === customerPricingBook?.pricingBookId &&
                moment(invoiceInformation?.issue_date).isSameOrAfter(moment(customerPricingBook?.startDate)) &&
                moment(invoiceInformation?.due_date).isSameOrBefore(moment(customerPricingBook?.endDate))
            )
            if (subscriptionEntity?.id) {
              subscriptionPrice = subscriptionEntity?.value
            } else {
              const defaultPricing = subscription?.product?.prices?.find(
                item =>
                  item.currencyId === currency?.id && item.type === licenceTypeFormatted && item.pricingBook?.is_default
              )
              subscriptionPrice = defaultPricing?.value || 0
            }
          } else {
            const subscriptionEntity = subscription?.product?.prices?.find(
              item =>
                item.currencyId === currency?.id && item.type === licenceTypeFormatted && item.pricingBook?.is_default
            )
            subscriptionPrice = subscriptionEntity?.value || 0
          }
        }
      })
    }

    const startDate = moment(discount?.startDate, 'YYYY-MM-DD')
    const endDate = moment(discount?.endDate, 'YYYY-MM-DD')

    const days = endDate.diff(startDate, 'days')

    if (days <= 30) {
      period = days > 1 ? days + ' days' : days + ' day'
    } else if (days < 365 && days > 30) {
      const months = days / 30
      period = `${parseInt(months)} ${parseInt(months) > 1 ? 'months' : 'month'}`
    } else if (days >= 365) {
      const years = days / 365
      const restDaysFromYears = days % 356
      if (restDaysFromYears > 30) {
        period = `${parseInt(years)} ${parseInt(years) > 1 ? 'years' : 'year'}`
      } else if (restDaysFromYears < 30 && restDaysFromYears > 0) {
        period = `${parseInt(years)} ${parseInt(years) > 1 ? 'years' : 'year'}`
      }
    }
    const discountText = totalDiscounts > 0 ? 'Discount: ' + totalDiscounts + '% for ' + period : ''
    const listPriceTex = subscriptionPrice
      ? ' (List price: ' + (currency?.iso_code === 'EUR' ? '€' : '$') + (subscriptionPrice || 0) + ')'
      : ''
    return discountText + listPriceTex
  }

  //  Format cover period
  const coverPeriod = (intervals, itemType) => {
    if (type === 'advance') {
      if (itemType == 'change')
        return (
          'Prorated Charge to cover: ' +
          (intervals?.length > 0 &&
            moment(intervals?.[intervals?.length - 1]?.from).format('MM/DD/YYYY') +
              ' - ' +
              moment(intervals?.[0]?.to).format('MM/DD/YYYY'))
        )
      return (
        'Charge to cover: ' +
        (intervals?.length > 0 &&
          moment(intervals?.[intervals?.length - 1]?.from).format('MM/DD/YYYY') +
            ' - ' +
            moment(intervals?.[0]?.to).format('MM/DD/YYYY'))
      )
    }
    return (
      'Charge to cover: ' +
      (intervals?.length > 0 &&
        moment(intervals[intervals?.length - 1]?.from).format('MM/DD/YYYY') +
          ' - ' +
          moment(intervals[intervals?.length - 1]?.to).format('MM/DD/YYYY'))
    )
  }

  // Finc Product
  const workSpaceProduct = subscription_id => {
    return customerInformation?.googleIds?.some(googleId => {
      const product = googleId?.subscriptions?.find(sub => sub.subscriptionId === subscription_id)?.product
      return !product?.is_isv_product && !product?.is_premiercloud_product
    })
  }

  const formatSkuName = sku_name => {
    const name = sku_name.includes('Google Workspace')
      ? 'Google Workspace Licence -' + sku_name.replace('Google Workspace', '')
      : sku_name
    return name
  }

  return (
    <TableRow key={row.id} sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}>
      <TableCell component='th' scope='row'>
        {formatDate(row.intervals).join(' - ') ?? ''}
      </TableCell>
      <TableCell>
        {workSpaceProduct(row.subscription_id) ? formatSkuName(row.sku_name) : row.sku_name}
        <br />
        {'Domain: ' + findDomain(row.subscription_id)}
        <br />
        {row.type !== 'billedUsage' && coverPeriod(row.intervals, row.type)}
        {row.type !== 'billedUsage' ? <br /> : null}
        {row.type !== 'billedUsage'
          ? formatDiscountDate(row.discount?.[0], row.subscription_id, row?.licence_type, row.discount)
          : null}
        {row.type === 'billedUsage' && 'Prorated credit for amount already paid'}
        {row.type === 'billedUsage' ? <br /> : null}
        {row.type === 'billedUsage' && row.label}
      </TableCell>
      <TableCell align='right'>{row.seats < 0 ? row.seats * -1 : row.seats}</TableCell>
      <TableCell align='right'>{calculateSingleRow(row.prices, row.total, row)}</TableCell>
    </TableRow>
  )
}

export default React.memo(TransactionsTableRow)
