// ** React Imports
import React, { useState } from 'react'

// ** MUI Imports
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

// ** Icon Imports
import SinglePlan from '../cards/SinglePlan'
import FlexiblePlan from '../cards/FlexiblePlan'
import AnnualPlan from '../cards/AnnualPlan'

const PaymentPlan = props => {
  // ** Props
  const { product, setSelectedPlan, selectedPlan, setLicensedNumberOfSeats, setStartDate, startDate } = props

  // ** State
  const [active, setActive] = useState(selectedPlan)

  // ** Constants
  const flexiblePlans = product?.prices?.some?.(item => item.type === 'flexible')
  const annualPlans = product?.prices?.some?.(item => item.type === 'annual')

  // ** Functions
  return (
    <Grid item container xs={12} display='flex' flexDirection='column' gap={3}>
      <Typography fontSize={20} fontWeight={500}>
        Review your payment plan
      </Typography>
      {flexiblePlans ? (
        <FlexiblePlan
          multiple={flexiblePlans && annualPlans}
          product={product}
          setActive={setActive}
          active={active}
          setSelectedPlan={setSelectedPlan}
          setLicensedNumberOfSeats={setLicensedNumberOfSeats}
        />
      ) : null}
      {annualPlans ? (
        <AnnualPlan
          multiple={flexiblePlans && annualPlans}
          product={product}
          setActive={setActive}
          active={active}
          setSelectedPlan={setSelectedPlan}
          setLicensedNumberOfSeats={setLicensedNumberOfSeats}
          setStartDate={setStartDate}
          startDate={startDate}
        />
      ) : null}
      {/* {offers?.map(({ offer }, index) => (
        <SinglePlan
          key={index}
          index={index}
          offer={offer}
          multiple={offers?.length > 1}
          setActive={setActive}
          setSelectedOffer={setSelectedPlan}
          active={active}
        />
      ))} */}
      <Box p='5px 5px 25px 5px'>
        <Typography variant='body2' fontSize={12} fontWeight={500} mb={1}>
          Any applicable taxes and discounts will be applied on your monthly invoice. Prices exclude applicable taxes
          and VAT (varies based on your country). <a href='#'>Learn more</a>
        </Typography>
        <Typography fontSize={12} fontWeight={500} variant='body2'>
          By clicking <strong>Checkout</strong>, you agree to the&nbsp;
          <span style={{ color: '#3367d6' }}>Google Workspace</span>and
          <span style={{ color: '#3367d6' }}> Supplemental Terms and Conditions for Google Workspace Free Trial </span>
          agreement. Notwithstanding anything to the contrary, any previous purchase of any Services will also be
          subject to the above terms and these terms will supersede any previous terms agreed to by the Customer and
          Google for any Services.
        </Typography>
      </Box>
    </Grid>
  )
}

export default PaymentPlan
