//REGISTER
export const POST_GET_ACCESS = 'auth/admins/access'
export const GET_APP_ACCESS = 'auth/admins/appsaccess'
export const POST_REFRESH_FRESHBOOK_TOKEN = 'customers/freshbooks/refresh'

// CUSTOMERS
export const GET_CUSTOMERS = 'customers'
export const GET_CUSTOMERS_TO_BILL = 'customers/customersToBill'
export const POST_CUSTOMERS_MULTIPLE_BILL = 'customers/multipleBilling'
export const GET_ONBOARDING_CUSTOMERS = 'googlecustomer'
export const GET_GOOGLE_CUSTOMER = 'googlecustomer/'
export const GET_CUSTOMER_CONTACTS = 'customers/reseller/cloudidentity/'
export const GET_BOARDED_CUSTOMER = 'customers/'
export const GET_CUSTOMER_BY_FRESHBOOKS = '/customers/freshbooks/customers/'
export const GET_SYNCHRONIZE_CUSTOMERS = 'customers/synchronizeCustomers'
export const GET_EXPORT_CUSTOMERS = 'customers/allCustomers'
export const GET_EXPORT_SUBSCRIPTIONS = 'customers/allsubscriptions'
export const QUERY_SEED_SUBSCRIPTIONS = 'customers/seedSubscriptionData'
export const POST_BULK_PRICING_BOOK_SUBSCRIPTION = 'customers//bulkCreateSubscriptionPricingBook'
export const GET_UPDATE_INVOICE = 'invoices/updateInvoice'
export const POST_REGENERATE_INVOICE_FRESHBOOKS = 'invoices/generate'
export const GET_EXPORT_CUSTOMERS_SHEET = 'customers/allCustomersToSheet'
export const PUT_CUSTOMER_GOOGLE_ID = 'customers/splitCustomer'

// Subscriptions
export const PUT_SUBSCRIPTIONS = 'subscriptions/'
export const GET_CUSTOMER_SUBSCRIPTIONS = 'customers/reseller/subscriptions/'
export const PRODUCTS = 'products/'
export const POST_RECALCULATIONS = 'dailyUsage/recalculation'
export const POST_CREATE_NON_GOOGLE_SKU_SUBSCRIPTION = 'subscriptions/'
export const POST_CREATE_DAILY_USAGE = 'dailyUsage/createDailyUsage/'
export const POST_CREATE_DAILY_CHANGES = 'dailyUsage/createChange/'
export const GET_CUSTOMER_DAILY_CHANGES = 'customers/dailyChanges/'
export const GET_EXPORT_SKUS_SHEET = 'customers/allProducts'

// Freshbooks
export const GET_FRESHBOOKS_SUGGESTIONS = 'customers/freshbooks/suggestion/'
export const CREATE_NEW_FRESHBOOKS = 'customers/freshbooks/customers'
export const POST_SEND_INVOICE_FRESHBOOKS_EMAIL = 'invoices/sendInvoiceEmail'

// ** METADATA
export const GET_TAXDATA = '/api/metadata/type/tax'
export const CRUD_METDATA = 'metadata/'
export const GET_PRICING_BOOKS = 'metadata/type/pricing_book'
export const POST_PRICING_BOOKS = 'metaData/pricing_book'
export const PUT_PRICING_BOOKS = 'metaData/pricing_book/'
export const PUT_DEFAULT_PRICING = 'customers/setDefaultPricingBook'
export const PUT_CUSTOMERS_PRICING_BULK = 'customers/bulkUpdatePricingBook'

// Invoices
export const GET_CHECK_BILLED_DAYS = `${process.env.REACT_APP_GET_CHECK_BILLED_DAYS}`
export const GET_CUSTOMER_INVOICES = `${process.env.REACT_APP_GET_CUSTOMER_INVOICES}`
export const GET_CUSTOMER_INVOICE = `${process.env.REACT_APP_GET_CUSTOMER_INVOICE}`
export const GENERATE_INVOICE = `${process.env.REACT_APP_GENERATE_INVOICE}`
export const POST_CREATE_MONTHLY_USAGE = 'dailyUsage/createMonthlyUsage'
export const GET_DAILYUSAGE_INVOICE = 'dailyUsage/invoice/'
export const GET_CUSTOMER_GOOGLE_USAGE = 'dailyUsage/googleUsage'
export const GET_CUSTOMER_INVOICES_HISTORY = 'dailyUsage/getInvoiceHistory'
export const UPLOAD_GOOGLE_USAGE = 'dailyUsage/createGoogleUsage'
export const GET_CUSTOMER_MONTH_CONSUMPTION = `${process.env.REACT_APP_GET_MONTH_CONSUMPTION}`
export const GET_COMPARE_USAGES = 'dailyUsage/compareUsages'
export const GET_COMPARE_FRESHBOOK_PRICE = 'invoices/freshbooks/comparaison'
export const GET_CHECK_DAILYUSAGES = 'dailyUsage/getDailyUsageCheck'
export const GET_GCP_USAGE = 'dailyUsage/gcpUsage/'

// Commissions
export const GET_RULES = 'commissionRules/'
export const CRUD_RULES = 'commissionRules/'
export const GET_ADMINS_GROUP = 'auth/admins/groups'
export const GET_SALES_AGENT = 'salesAgents/'
export const GET_OPPORTUNITIES = 'opportunities/'
export const GET_ALL_PAYOUTS = 'payouts/'
export const POST_PAY_PAYOUT = 'payouts/pay'
export const GET_AGENT_DEALS = 'opportunities/'

// Invoice
export const CRUD_INVOICE_TEMPALTE = 'invoiceTemplates/'
