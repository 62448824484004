// Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './apiCaching'

// ** moment
import moment from 'moment'

import {
  getBoardedCustomersApi,
  getGoogleCustomer,
  getGoogleCustomerContact,
  getOnboardingCustomers,
  getBoardedCustomerApi,
  getCustomerSubscriptions,
  getCustomerByFreshbooksId,
  createCustomerApi,
  updateCustomerApi,
  updateGoogleCustomerApi,
  synchronizeCustomers,
  getInvoices,
  getInvoice,
  updateInvoiceFreshbooks,
  generateInvoiceApi,
  regenrateInvoice,
  createNonGoogleSkuSubscription,
  createDailyChange,
  createDailyUsage,
  updateNonGoogleSkuSubscriptionApi,
  fetchCustomerDailyUsages,
  getDailyUsageInvoice,
  getMetaDataData,
  fetchCustomerGoogleUsage,
  getCustomerInvoicesHistory,
  getCustomerMonthConsumption,
  getUsagesToCompare,
  deleteCustomer,
  recalculateDailyUsage,
  getCheckDailyUsages,
  updateCustomerSubscription,
  getGcpUsage,
  getPricesFresbooksToCompare,
  addInvoiceTemplate,
  getInvoiceTemplates,
  updateInvoiceTemplate,
  deleteInvoiceTemplate,
  sendInvoiceByEmailFreshbooksApi
} from 'configs/axios/api_helper'

export const initialState = {
  loading: null,
  customersOnboarding: [],
  customerInformation: {},
  boardedCustomers: [],
  organizationSuggestions: [],
  freshbookSugg: {},
  invoiceInformation: [],
  customerDailyChanges: [],
  customerInvoices: [],
  customerGoogleUsages: [],
  invoicesHistory: [],
  customersGoogleUsagesCompare: [],
  customersFreshbooksUsagesCompare: [],
  checkDailyUsages: [],
  invoiceTemplates: [],
  billingSearch: {
    //used to persist the params of the filtering in billing interface
    billingDay: '',
    organization: '',
    freshbooksId: ''
  },
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    addBoardedCustomer: (state, action) => {
      state.boardedCustomers.push(action.payload)
      state.success = 'CREATE_CUSTOMER'
      state.loading = null
    },
    addBoardedCustomerWithGoogleFail: state => {
      state.success = 'CREATE_CUSTOMER_GOOGLE_FAIL'
      state.loading = null
    },
    updateCustomer: (state, action) => {
      const { message, ...rest } = action.payload
      state.customerInformation = { ...state.customerInformation, ...rest }
      state.success = message
      state.loading = null
    },
    updateSubscription: (state, action) => {
      state.customerInformation = {
        ...state.customerInformation,
        googleIds: state.customerInformation.googleIds.map(googleId => ({
          ...googleId,
          subscriptions: googleId.subscriptions?.map(subscription =>
            subscription.subscriptionId.toString() === action.payload.subscriptionId.toString()
              ? { ...subscription, ...action.payload }
              : subscription
          )
        }))
      }
      state.success = 'UPDATE_SUBSCRIPTION'
      state.loading = null
    },
    updateCustomersList: (state, action) => {
      state.customersOnboarding = [...state.customersOnboarding, ...action.payload]
      state.success = 'SYNCHRONIZE_CUSTOMERS'
      state.loading = null
    },
    removeCustomer: (state, action) => {
      state.customersOnboarding = state.customersOnboarding.filter(tax => tax.id !== action.payload)
      state.success = 'DELETE'
      state.loading = null
    },
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    setOrganizationSuggestionsSuccess: (state, action) => {
      state.organizationSuggestions = action.payload
      state.success = 'GET'
      state.loading = null
    },
    setOrganizationSuggestionsFailure: state => {
      state.error = 'ERROR'
      state.loading = null
    },
    setCustomerSuccess: (state, action) => {
      state.customerInformation = { ...action.payload }
      state.success = 'GET'
      state.loading = null
    },
    setCustomerFailure: (state, action) => {
      state.error = action?.payload || ''
      state.loading = null
    },
    getCustomersSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.boardedCustomers = data
      state.pagination = filters
      state.loading = null
      state.success = 'GET'
    },
    getCustomersFailure: state => {
      state.loading = null
      state.error = 'ERROR'
    },
    getCustomersOnboardingSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.customersOnboarding = data
      state.pagination = filters
      state.loading = null
      state.success = 'GET'
    },
    sendInvoiceSuccess: (state, action) => {
      state.invoiceInformation = { ...state.invoiceInformation, freshbook: { ...action.payload.freshbook } }
      state.loading = false
      state.success = 'SEND_INVOICE'
    },
    updateInvoiceSuccess: (state, action) => {
      state.invoiceInformation = {
        ...state.invoiceInformation,
        status: action.payload.status,
        freshbook: { ...action.payload.freshbook }
      }
      state.customerInvoices = state.customerInvoices.map(invoice =>
        invoice.id.toString() === action.payload.id.toString() ? { ...invoice, status: action.payload.status } : invoice
      )
      state.loading = false
      state.success = 'REFRESH_INVOICE'
    },
    sendInvoiceByEmailSuccess: (state, action) => {
      state.success = 'SEND_INVOICE_BY_EMAIL'
      state.loading = null
    },
    generateInvoiceSuccess: (state, action) => {
      //state.invoiceInformation.push(action.payload);
      state.success = 'GENERATE_INVOICE'
      state.loading = null
    },
    getInvoicesSuccess: (state, action) => {
      state.customerInvoices = action.payload
      state.invoiceInformation = action.payload
      state.loading = null
      state.success = 'GET_INVOICES'
    },
    getGoogleUsagesSuccess: (state, action) => {
      state.customerGoogleUsages = action.payload
      state.loading = null
      state.success = 'GET_GOOGLE_USAGES'
    },
    getCustomersUsagesCompareSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.customersGoogleUsagesCompare = data
      state.pagination = filters
      state.loading = null
      state.success = 'GET_CUSTOMERS_MONTHLY_USAGES'
    },
    getCustomersFreshbooksCompareSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.customersFreshbooksUsagesCompare = data
      state.pagination = filters
      state.loading = null
      state.success = 'GET_CUSTOMERS_MONTHLY_FRESHBOOKS_USAGES'
    },
    getCustomersDailyUsagesSuccess: (state, action) => {
      state.checkDailyUsages = action.payload
      state.loading = null
      state.success = 'GET_CHECK_DAILY_USAGES'
    },
    getInvoicesHistorySuccess: (state, action) => {
      state.invoicesHistory = action.payload
      state.loading = null
      state.success = 'GET_INVOICES_HISTORY'
    },
    getDailyChangesSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.customerDailyChanges = data
      state.pagination = filters
      state.loading = null
      state.success = 'GET_CUSTOMER_DAILY_CHANGES'
    },
    getInvoiceTemplatesSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.invoiceTemplates = data
      state.pagination = filters
      state.loading = null
      state.success = 'GET_INVOICE_TEMPLATES'
    },
    getInvoiceSuccess: (state, action) => {
      state.invoiceInformation = action.payload
      state.loading = null
      state.success = 'GET_INVOICE'
    },
    removeInvoiceSuccess: (state, action) => {
      state.customerInvoices = state.customerInvoices.filter(
        invoice => invoice.id.toString() !== action.payload.toString()
      )
      state.loading = null
      state.success = 'REMOVE_INVOICE'
    },
    getCustomersOnboardingFailure: state => {
      state.loading = null
      state.error = 'ERROR'
    },
    persistSearchingBilling: (state, action) => {
      state.billingSearch = action.payload
    },
    setErrorMessage: (state, action) => {
      state.loading = null
      state.error = action.payload
      state.success = ''
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.loading = null
    }
  }
})

// Actions generated from the slice
const {
  addCustomer,
  addBoardedCustomer,
  addBoardedCustomerWithGoogleFail,
  updateCustomer,
  updateSubscription,
  removeCustomer,
  removeInvoiceSuccess,
  setErrorMessage,
  resetMessages,
  generateInvoiceSuccess,
  sendInvoiceSuccess,
  getInvoiceSuccess,
  getInvoicesSuccess,
  getGoogleUsagesSuccess,
  getInvoicesHistorySuccess,
  getCustomersUsagesCompareSuccess,
  getCustomersFreshbooksCompareSuccess,
  updateInvoiceSuccess,
  startLoading,
  getCustomersSuccess,
  getCustomersFailure,
  setCustomerSuccess,
  setCustomerFailure,
  getCustomersOnboardingFailure,
  getCustomersOnboardingSuccess,
  persistSearchingBilling,
  getDailyChangesSuccess,
  getInvoiceTemplatesSuccess,
  getCustomersDailyUsagesSuccess,
  sendInvoiceByEmailSuccess,
  setOrganizationSuggestionsSuccess,
  setOrganizationSuggestionsFailure
} = customersSlice.actions

// export user selector to get the slice in any component
export const customersSelector = state => state.customers

// export The reducer
const customersReducer = customersSlice.reducer
export default customersReducer

// Utils
// Calculate single row
const calculateSingleRow = (prices, total, customerCurrencyId, type) => {
  if (type === 'advance') return total < 0 ? total?.toFixed(2) * -1 : total?.toFixed(2)
  const price = prices?.find(item => item.currencyId === customerCurrencyId)?.totalValue
  if (price < 0) return -1 * ((price || 0) * -1)?.toFixed(2)
  else return (price || 0)?.toFixed(2)
}

const splitArrayBySub = inputArray => {
  const groupedArrays = inputArray.reduce((result, obj) => {
    // Check if there is an array for the current subscription_id, if not, create one
    if (!result[obj.subscription_id]) {
      result[obj.subscription_id] = []
    }

    // Push the object to the corresponding subscription_id array
    result[obj.subscription_id].push(obj)

    return result
  }, {})

  // Convert the groupedArrays object to an array of arrays
  return Object.values(groupedArrays)
}

const formatDiscountDate = (customerInformation, discount, skuId, licenceType, discounts) => {
  const totalDiscounts = discounts?.reduce((acc, { value }) => acc + parseFloat(value || 0), 0)?.toFixed(2)
  let subscriptionPrice = null
  let period = null
  const licenceTypeFormatted = licenceType === 'FREE' ? '' : licenceType === 'FLEXIBLE' ? 'flexible' : 'annual'
  if (!!skuId) {
    customerInformation?.googleIds?.forEach(googleId => {
      const subscription = googleId?.subscriptions?.find(sub => sub?.subscriptionId === skuId) ?? null
      if (!!subscription) {
        const customerPricingBook = customerInformation?.subscriptionPricingBooks?.find(
          item => item.subscriptionId === subscription?.subscriptionId
        )
        if (!!customerPricingBook?.pricingBookId) {
          const subscriptionEntity = subscription?.product?.prices?.find(
            item =>
              item.currencyId === currency?.id &&
              item.type === licenceTypeFormatted &&
              item.pricingBookId === customerPricingBook?.pricingBookId &&
              moment(invoiceInformation?.issue_date).isSameOrAfter(moment(customerPricingBook?.startDate)) &&
              moment(invoiceInformation?.due_date).isSameOrBefore(moment(customerPricingBook?.endDate))
          )
          if (subscriptionEntity?.id) {
            subscriptionPrice = subscriptionEntity?.value
          } else {
            const defaultPricing = subscription?.product?.prices?.find(
              item =>
                item.currencyId === currency?.id && item.type === licenceTypeFormatted && item.pricingBook?.is_default
            )
            subscriptionPrice = defaultPricing?.value || 0
          }
        } else {
          const subscriptionEntity = subscription?.product?.prices?.find(
            item =>
              item.currencyId === currency?.id && item.type === licenceTypeFormatted && item.pricingBook?.is_default
          )
          subscriptionPrice = subscriptionEntity?.value || 0
        }
      }
    })
  }

  const startDate = moment(discount?.startDate, 'YYYY-MM-DD')
  const endDate = moment(discount?.endDate, 'YYYY-MM-DD')

  const days = endDate.diff(startDate, 'days')

  if (days <= 30) {
    period = days > 1 ? days + ' days' : days + ' day'
  } else if (days < 365 && days > 30) {
    const months = days / 30
    period = `${parseInt(months)} ${parseInt(months) > 1 ? 'months' : 'month'}`
  } else if (days >= 365) {
    const years = days / 365
    const restDaysFromYears = days % 356
    if (restDaysFromYears > 30) {
      period = `${parseInt(years)} ${parseInt(years) > 1 ? 'years' : 'year'}`
    } else if (restDaysFromYears < 30 && restDaysFromYears > 0) {
      period = `${parseInt(years)} ${parseInt(years) > 1 ? 'years' : 'year'}`
    }
  }
  const discountText = totalDiscounts > 0 ? 'Discount: ' + totalDiscounts + '% for ' + period : ''
  const listPriceTex = subscriptionPrice
    ? ' (List price: ' + (currency?.iso_code === 'EUR' ? '€' : '$') + (subscriptionPrice || 0) + ')'
    : ''
  return discountText + listPriceTex
}

const regroupUsages = (invoiceDetails, issueDate, currencyId) => {
  // Result is to have 2 rows :
  // 1st presents the unbilled old period which is before this invoice issue date
  // 2nd presents the currenct period of the generated invoice
  const tableRows = []
  for (const item of invoiceDetails) {
    // Concatinating old usages together which is before this advance invoice issue date.
    if (new Date(issueDate) > new Date(item.day)) {
      const total = item.prices?.find(item => item.currencyId === currencyId)?.totalValue || 0
      const totalSeats = item?.seats
      tableRows.push({ ...item, total, totalSeats })
    } else {
      // Reaching the current invoice issue date we concat all rows together
      if (
        tableRows.some(el => el.subscription_id === item.subscription_id && new Date(issueDate) <= new Date(el.day))
      ) {
        const row = tableRows?.find(
          el => el.subscription_id === item.subscription_id && new Date(issueDate) <= new Date(el.day)
        ) // get existing row that will be updated

        const index = tableRows?.findIndex(
          el => el.subscription_id === item.subscription_id && new Date(issueDate) <= new Date(el.day)
        ) // index of the existing row
        const total = (item.prices?.find(item => item.currencyId === currencyId)?.totalValue || 0) + (row?.total || 0)
        tableRows[index].days = [...row?.days, ...item?.days]
        tableRows[index].intervals = [...item?.intervals, ...row?.intervals]
        tableRows[index].prices = [...item?.prices, ...row?.prices]
        tableRows[index].total = total
      } else {
        const total = item.prices?.find(item => item.currencyId === currencyId)?.totalValue || 0
        const totalSeats = item?.seats
        tableRows.push({ ...item, total, totalSeats })
      }
    }
  }
  return tableRows
}

// Freshbooks invoice cover period
function coverPeriod(intervals, type, itemType) {
  if (type === 'advance') {
    if (itemType == 'change')
      return (
        'Prorated Charge to cover: ' +
        (intervals?.length > 0 && intervals?.[intervals?.length - 1]?.from + ' - ' + intervals?.[0]?.to)
      )
    else
      return (
        'Charge to cover: ' +
        (intervals?.length > 0 && intervals?.[intervals?.length - 1]?.from + ' - ' + intervals?.[0]?.to)
      )
  }
  return (
    'Charge to cover: ' +
    (intervals?.length > 0 && intervals[intervals?.length - 1]?.from + ' - ' + intervals[intervals?.length - 1]?.to)
  )
}

// Sort invoice lines before sending to freshbooks
const splitIntervals = inputArray => {
  const resultArray = []

  inputArray.forEach(item => {
    if (item.intervals?.length > 1) {
      item.intervals.forEach(interval => {
        const from = moment(interval.from)
        const to = moment(interval.to)
        const newItem = {
          ...item,
          day: interval.from,
          days: item.days?.filter(date => {
            const currentDate = moment(date)
            return currentDate.isBetween(from, to, null, '[]')
          }),
          intervals: [interval]
        }
        resultArray.push(newItem)
      })
    } else {
      resultArray.push(item)
    }
  })

  // Sort Array by sku name & day at the same time
  resultArray.sort((a, b) => {
    // Compare sku_name
    const nameComparison = a.sku_name?.localeCompare(b.sku_name)

    // If sku_name is the same, compare by day
    if (nameComparison === 0) {
      return a.intervals[0]?.from?.localeCompare(b.intervals[0]?.from)
    }

    return nameComparison
  })
  return resultArray
}

// Actions
export const fetchCustomersOnboarding =
  ({ limit, page, field } = {}) =>
  async dispatch => {
    dispatch(startLoading('GET'))
    const response = await getOnboardingCustomers({ limit, page, status: 'pending', field })
    if (response.status === 200 || response.status === 201) {
      const { data } = response
      const { data: customers, ...rest } = data
      const filteredArray = customers?.filter((obj, index, array) => {
        // Filter out objects with the same id that occur later in the array
        return array.findIndex(item => item.customerId === obj.customerId) === index
      })
      dispatch(getCustomersOnboardingSuccess({ ...rest, data: filteredArray }))
    } else if (data?.response?.status !== 200) {
      dispatch(getCustomersOnboardingFailure())
    }
  }

export const fetchBoardedCustomers =
  ({ billingDay, organization, freshbooksId, limit, page } = {}) =>
  async dispatch => {
    dispatch(startLoading('GET'))
    const response = await getBoardedCustomersApi({ billingDay, organization, freshbooksId, limit, page })
    if (response?.status === 200 || response?.status === 201) {
      dispatch(getCustomersSuccess(response.data))
    } else if (response?.response?.status !== 200) {
      dispatch(getCustomersFailure())
    }
  }

export const getCustomerOnboarding = id => async dispatch => {
  dispatch(startLoading('GET_CUSTOMER_ONBOARDING'))
  const data = await getGoogleCustomer(id)
  if (data.status === 200 || data.status === 201) {
    const { data: customer } = data
    const response = await getGoogleCustomerContact(customer.domain)
    if (response.status === 200) {
      const { data: customerContact } = response
      let fullNameArray = customerContact?.displayName?.split(' ')
      dispatch(
        setCustomerSuccess({
          ...customer,
          contactDisplayName: customerContact?.displayName || '',
          contactEmail: customerContact?.email || '',
          contactPhone: customerContact?.phone || '',
          contactFirstName: customerContact?.firstName || (fullNameArray?.length > 1 && fullNameArray[0]) || '',
          contactLastName: customerContact?.lastName || (fullNameArray?.length > 1 && fullNameArray[1]) || '',
          contactTitle: customerContact?.title || ''
        })
      )
    } else if (response?.response?.status !== 200) {
      dispatch(
        setCustomerSuccess({
          ...customer,
          contactDisplayName: '',
          contactEmail: '',
          contactPhone: '',
          contactFirstName: '',
          contactLastName: '',
          contactTitle: ''
        })
      )
    }
  } else if (data?.response?.status !== 200) {
    dispatch(setCustomerFailure())
  }
}

export const getBoardedCustomer = id => async dispatch => {
  dispatch(startLoading('GET'))
  const response = await getBoardedCustomerApi(id)
  const subResponse = await getCustomerSubscriptions(id)
  if (response?.status === 200 || response?.status === 201) {
    // ** Fetch the latest subscription entites from the api
    // and merge it with current subs nested under the customer entity
    const { data: customer } = response
    const { googleIds, ...rest } = customer
    if (subResponse?.status === 200 || subResponse?.status === 201) {
      const { data: latestSubs } = subResponse
      let updatedCustomer = {
        ...rest,
        googleIds: googleIds
          ?.filter((sub, index, array) => array?.findIndex(duplicate => duplicate?.domain == sub?.domain) == index)
          ?.map(item => {
            const { subscriptions, ...rest } = item
            return {
              ...rest,
              subscriptions: subscriptions
                ?.filter(
                  item =>
                    latestSubs?.some(el => el?.subscriptionId == item?.subscriptionId) || item.is_not_google_product
                )
                ?.filter(
                  (sub, index, array) =>
                    array?.findIndex(duplicate => duplicate?.subscriptionId == sub?.subscriptionId) == index
                )
                ?.map(sub => {
                  // Fetch current sub from the latest sub's array
                  let latestSub = latestSubs?.find(el => el?.subscriptionId == sub?.subscriptionId)
                  // Destructring the properties needed to be updated
                  if (latestSub && latestSub !== undefined) {
                    const { status, seats, plan } = latestSub
                    return {
                      ...sub,
                      status: status,
                      numberOfSeats: seats?.numberOfSeats || null,
                      licensedNumberOfSeats: seats?.licensedNumberOfSeats || 0,
                      planName: plan?.planName
                    }
                  } else {
                    return sub
                  }
                })
            }
          })
      }
      dispatch(setCustomerSuccess(updatedCustomer))
    } else if (subResponse?.response?.status !== 200) {
      dispatch(setCustomerSuccess(customer))
    }
  } else if (response?.response?.status !== 200) {
    dispatch(setCustomerFailure())
  }
}

export const deleteBoardedCustomer =
  (id, navigate = null) =>
  async dispatch => {
    dispatch(startLoading('DELETE_CUSTOMER'))
    try {
      const response = await deleteCustomer(id)
      navigate('/customers/customers-list')
      dispatch(startLoading(null))
      dispatch(apiSlice.util.resetApiState())
    } catch (error) {
      dispatch(startLoading(null))
    }
  }

export const getBoardedCustomerInformationOnly = id => async dispatch => {
  dispatch(startLoading('GET'))
  try {
    const response = await getBoardedCustomerApi(id)
    const { data: customer } = response
    dispatch(setCustomerSuccess(customer))
  } catch (error) {
    dispatch(setCustomerFailure())
  }
}

export const createCustomer =
  (customer, navigate = null, refetch = null) =>
  async dispatch => {
    dispatch(startLoading('CREATE_CUSTOMER'))
    const {
      customerId,
      freshbooksId,
      createdAt,
      updatedAt,
      id,
      contactFirstName,
      contactLastName,
      contactPhone,
      contactTitle,
      contactEmail,
      googleId,
      currencyId,
      ...rest
    } = customer
    const findCustomerResponse = await getCustomerByFreshbooksId(freshbooksId)
    if (findCustomerResponse?.status === 200) {
      const { data: existingCustomer } = findCustomerResponse
      let updatedCustomer = {
        googleIds: [
          ...existingCustomer.googleIds,
          { googleId: customerId || googleId, domain: customer.domain, is_free_domain: customer.is_free_domain }
        ],
        contacts: [
          ...existingCustomer.contacts,
          {
            firstname: contactFirstName || '',
            lastname: contactLastName || '',
            email: contactEmail || '',
            phone: contactPhone || '',
            title: contactTitle || ''
          }
        ]
      }
      const response = await updateCustomerApi(existingCustomer.id, {
        ...updatedCustomer,
        billingCurrencyId: currencyId
      })
      if (id !== undefined && id) {
        const updateResponse = await updateGoogleCustomerApi(id, { status: 'boarded' })
      }
      navigate('/customers/customers-list')
      refetch && refetch()
      if (response.status === 201 || response.status === 200) {
        const { data } = response
        dispatch(addBoardedCustomer(data))
      } else {
        dispatch(setErrorMessage('CREATE_CUSTOMER'))
      }
    } else if (findCustomerResponse?.response?.status !== 200) {
      let newCustomer = {
        ...rest,
        status: 'active',
        freshbooksId: freshbooksId,
        googleIds: [
          { googleId: customerId || googleId, domain: customer.domain, is_free_domain: customer.is_free_domain }
        ],
        ...(contactEmail && {
          contacts: [
            {
              firstname: contactFirstName || '',
              lastname: contactLastName || '',
              email: contactEmail,
              phone: contactPhone || '',
              title: contactTitle || ''
            }
          ]
        })
      }
      try {
        const response = await createCustomerApi(newCustomer)
        if (id !== undefined && id) {
          const updateResponse = await updateGoogleCustomerApi(id, { status: 'boarded' })
        }
        if (response?.id) {
          const newCustomer = await updateCustomerApi(response?.id, { billingCurrencyId: currencyId })
          dispatch(setCustomerSuccess(newCustomer?.id ? newCustomer : response))
          await recalculateDailyUsage({
            customers_id: [response?.id?.toString()],
            prices_array: [],
            startDate: moment(createdAt).format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            change_price: 'true',
            change_discount: 'true'
          })
          navigate(`/customers/customer-display/${response?.id}`)
          refetch && refetch()
        } else {
          navigate('/customers/customers-list')
          refetch && refetch()
        }
      } catch (error) {
        dispatch(addBoardedCustomerWithGoogleFail())
        dispatch(setErrorMessage('CREATE_CUSTOMER'))
        setTimeout(() => {
          navigate('/customers/onboarding-list')
        }, 2000)
      }
    }
  }

export const editCustomer = customer => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_CUSTOMER'))
    // excluding googleIds (just added it when the usecase is ready)
    const {
      customerId,
      googleIds,
      discounts,
      taxes,
      createdAt,
      updatedAt,
      id,
      contactId,
      contactFirstName,
      contactLastName,
      contactPhone,
      contactTitle,
      contactEmail,
      selectedFreeDomain,
      is_free_domain,
      ...rest
    } = customer
    const exist = rest.contacts.length // check if conacts array already have user if yes update the old array if no create new contact
    const domain = selectedFreeDomain ? googleIds?.find(item => item.id === selectedFreeDomain) : null
    const updatedDomains = selectedFreeDomain ? googleIds?.filter(item => item.id !== selectedFreeDomain) : null
    const updatedBody = {
      ...rest,
      ...(selectedFreeDomain
        ? {
            googleIds: [...updatedDomains, { ...domain, is_free_domain: is_free_domain ? true : false }]
          }
        : {}),
      ...(!exist && {
        contacts: [
          {
            firstname: contactFirstName,
            lastname: contactLastName,
            email: contactEmail,
            phone: contactPhone,
            title: contactTitle
          }
        ]
      }),
      ...(exist && {
        contacts: [
          {
            id: contactId,
            firstname: contactFirstName,
            lastname: contactLastName,
            email: contactEmail,
            phone: contactPhone,
            title: contactTitle
          }
        ]
      })
    }
    const response = await updateCustomerApi(id, updatedBody)
    const { googleIds: oldSubs, ...data } = response
    dispatch(updateCustomer({ ...data, id: id, message: 'UPDATE' }))
  } catch (error) {
    console.log('---error---', error)
    dispatch(getCustomersFailure())
  }
}

export const manageContacts = contact => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_CONTACT'))
    const { id, contacts, deletedContacts } = contact
    let updatedBody = {}
    if (deletedContacts != undefined) {
      updatedBody = { deletedContacts: deletedContacts }
    } else if (Array.isArray(contacts)) updatedBody = { contacts: contacts }
    else updatedBody = { contacts: [{ ...contacts }] }

    let response = await updateCustomerApi(id, updatedBody)
    let { googleIds, ...data } = response
    dispatch(updateCustomer({ ...data, id: id, message: 'UPDATE' }))
  } catch (error) {
    dispatch(setErrorMessage('UPDATE_CONTACT'))
  }
}

export const editCustomerBilling = customer => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_CUSTOMER'))
    const { id, billingDay, ...updatedBody } = customer
    let response = await updateCustomerApi(id, { ...updatedBody, billingDay: parseInt(billingDay) })
    let { googleIds, ...data } = response
    dispatch(updateCustomer({ ...data, id: id, message: 'UPDATE' }))
  } catch (error) {
    dispatch(getCustomersFailure())
  }
}

export const editSubDiscount = payload => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_SUBSCRIPTION'))
    const { customerId, discounts: array } = payload
    let response = await updateCustomerApi(customerId, { discounts: array })
    // Convert the date strings to Moment objects
    const startDate = moment(array?.[0]?.startDate, 'YYYY-MM-DD')
    const endDate = moment(array?.[0]?.endDate, 'YYYY-MM-DD')

    // Get today's date
    const today = moment()

    // Check if today's date falls between the start and end dates
    const isInInterval = today.isBetween(startDate, endDate, null, '[]') // '[]' includes start and end dates in the interval
    const inFuture = today.isBefore(startDate)
    if (!inFuture)
      await recalculateDailyUsage({
        customers_id: [customerId.toString()],
        prices_array: [],
        ...(isInInterval
          ? { startDate: array?.[0]?.startDate, endDate: today.format('YYYY-MM-DD') }
          : { startDate: array?.[0]?.startDate, endDate: array?.[0]?.endDate }),
        change_price: 'true',
        change_discount: 'true'
      })
    let { discounts, ...data } = response
    dispatch(updateCustomer({ discounts, message: 'UPDATE_SUBSCRIPTION' }))
  } catch (error) {
    dispatch(setErrorMessage('UPDATE_SUBSCRIPTION'))
  }
}

export const editMargin = payload => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_SUBSCRIPTION'))
    const { customerId, googleIds } = payload
    let response = await updateCustomerApi(customerId, { googleIds })
    dispatch(updateCustomer({ response, message: 'UPDATE_SUBSCRIPTION' }))
  } catch (error) {
    dispatch(setErrorMessage('UPDATE_SUBSCRIPTION'))
  }
}

export const editSubPricing = discount => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_SUBSCRIPTION'))
    const { customerId, ...updatedBody } = discount
    let response = await updateCustomerApi(customerId, { subscriptionPricingBooks: [updatedBody] })
    let { subscriptionPricingBooks, ...data } = response
    dispatch(updateCustomer({ subscriptionPricingBooks, message: 'UPDATE_SUBSCRIPTION' }))
  } catch (error) {
    dispatch(setErrorMessage('UPDATE_SUBSCRIPTION'))
  }
}

export const editCustomerSubscription = (customer, subscription) => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_CUSTOMER_SUBSCRIPTION'))
    let response = await updateCustomerSubscription(subscription.id, subscription)
    const googleIds = customer?.googleIds?.map(item => ({
      ...item,
      subscriptions: item.subscriptions.map(sub =>
        sub.subscriptionId === subscription.subscriptionId ? subscription : sub
      )
    }))
    dispatch(updateCustomer({ ...customer, googleIds, message: 'UPDATE_CUSTOMER_SUBSCRIPTION' }))
  } catch (error) {
    dispatch(setErrorMessage('UPDATE_CUSTOMER_SUBSCRIPTION'))
  }
}

export const getCustomerGoogleUsages =
  ({ id, from, to }) =>
  async dispatch => {
    try {
      dispatch(startLoading('GET_GOOGLE_USAGES'))
      const { data } = await fetchCustomerGoogleUsage({ from, to, id })
      if (!!data)
        dispatch(
          getGoogleUsagesSuccess(
            data
              ?.sort((a, b) => {
                return a.start_date?.localeCompare(b.start_date)
              })
              ?.reverse() || []
          )
        )
    } catch (error) {
      dispatch(setCustomerFailure('ERROR_CURRENT_SPENDING'))
    }
  }

export const getCustomerInvoices =
  ({ id, filters = {} }) =>
  async dispatch => {
    try {
      dispatch(startLoading('GET_INVOICES'))
      const { data } = await getInvoices(`?customerId=${id}`)
      if (!!data.result) dispatch(getInvoicesSuccess(data?.result || []))
    } catch (error) {
      dispatch(setCustomerFailure('ERROR_CURRENT_SPENDING'))
    }
  }

export const fetchCustomerInvoicesHistory =
  ({ id, from, to, fromMonth, toMonth }) =>
  async dispatch => {
    try {
      dispatch(startLoading('GET_INVOICES_HISTORY'))
      const { data } = await getCustomerInvoicesHistory({ from, to, id })
      const response = await getCustomerMonthConsumption({ from: fromMonth, to: toMonth, customerId: id })
      if (!!data)
        dispatch(
          getInvoicesHistorySuccess(
            [
              response?.data?.items?.length > 0
                ? { customer_id: id, start_date: fromMonth, end_date: toMonth, items: response?.data?.items }
                : {},
              ...(data || [])
            ]
              ?.sort((a, b) => {
                return a.start_date?.localeCompare(b.start_date)
              })
              ?.reverse()
          )
        )
    } catch (error) {
      dispatch(setCustomerFailure('ERROR_CURRENT_SPENDING'))
    }
  }

export const getCustomersUsages =
  ({ page, limit, from, to, search }) =>
  async dispatch => {
    try {
      dispatch(startLoading('GET_CUSTOMERS_MONTHLY_USAGES'))
      const { data } = await getUsagesToCompare({ from, to, page, limit, search })
      if (!!data?.data) {
        const currencyId = 2 // Candian Currency statis value
        const formattedArray = data?.data.map(item => {
          const totalGoogleUsage = item.googleUsageData?.reduce((acc, { price }) => acc + price, 0)?.toFixed(2)
          const totalInvoicesHistory = item.invoiceHistoryData?.[0]?.items
            ?.reduce(
              (acc, { prices, quantity }) =>
                acc + (prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0) * (quantity || 1),
              0
            )
            ?.toFixed(2)
          return {
            ...item,
            googleUsageData: item.googleUsageData
              ?.sort((a, b) => {
                return a.start_date?.localeCompare(b.start_date)
              })
              ?.reverse(),
            invoiceHistoryData: item.invoiceHistoryData
              ?.sort((a, b) => {
                return a.start_date?.localeCompare(b.start_date)
              })
              ?.reverse(),
            totalGoogleUsage: totalGoogleUsage || 0,
            totalInvoicesHistory: totalInvoicesHistory || 0
          }
        })
        dispatch(getCustomersUsagesCompareSuccess({ ...data, data: formattedArray }))
      }
    } catch (error) {
      console.log('---error---', error)
    }
  }

export const getFreshbooksPrices =
  ({ page, limit, from, to, search, billingType }) =>
  async dispatch => {
    try {
      dispatch(startLoading('GET_CUSTOMERS_MONTHLY_USAGES'))
      const { data } = await getPricesFresbooksToCompare({ from, to, page, limit, search, billingType })
      dispatch(getCustomersFreshbooksCompareSuccess({ data }))
    } catch (error) {
      dispatch(startLoading(''))
      console.log('---error---', error)
    }
  }

export const getCustomersDailyUsages =
  ({ page, limit, from, to, search }) =>
  async dispatch => {
    try {
      dispatch(startLoading('GET_CHECK_DAILY_USAGES'))
      const { data } = await getCheckDailyUsages({ from, to, page, limit })
      dispatch(getCustomersDailyUsagesSuccess(data))
    } catch (error) {
      console.log('---error---', error)
    }
  }

export const cleanCustomerInvoices = () => async dispatch => {
  dispatch(getInvoicesSuccess([]))
}

export const cleanCustomerGoogleUsages = () => async dispatch => {
  dispatch(getGoogleUsagesSuccess([]))
}
export const cleanCustomerInvoicesHistory = () => async dispatch => {
  dispatch(getInvoicesHistorySuccess([]))
}

export const cleanCustomersUsageComparator = () => async dispatch => {
  dispatch(
    getCustomersUsagesCompareSuccess({
      data: {},
      nextPage: null,
      previousPage: null,
      limit: 0,
      total: 0,
      currentPage: 1
    })
  )
}

export const getCustomerInvoice =
  ({ customerId, invoiceId, show_gcp_usage }) =>
  async dispatch => {
    try {
      dispatch(startLoading('GET_INVOICE'))
      let gcpInvoice = []
      const { data: dailyUsageInvoices } = await getDailyUsageInvoice(invoiceId)
      const { data: currenciesResponse } = await getMetaDataData('type/currency')
      const { data } = await getInvoice(`?customerId=${customerId}&invoiceId=${invoiceId}`)

      const extraInvoice = dailyUsageInvoices
        ?.filter(
          item =>
            item.subscription?.toLowerCase() !== 'credit' &&
            !item.subscription?.toLowerCase()?.includes('subscription') &&
            !item.description?.toLowerCase()?.includes('gst')
        )
        ?.map(item => ({
          resource_type: item.resource_type,
          type: item.description?.toLowerCase(),
          label: item.description + ' ' + item.day,
          day: item.start_date,
          description: item.description,
          subscription_id: '',
          discount: [],
          sku_name: item.subscription,
          plan: null,
          sku_id: '',
          licence_type: '',
          price_cad: 0,
          price_usd: 0,
          seats: 1,
          prices: currenciesResponse.data?.map(element => ({
            currencyId: element.id,
            totalValue: item.price
          })),
          days: [item.start_date, item.day],
          quantity: 1,
          intervals: [
            {
              from: item.start_date,
              to: item.day
            }
          ]
        }))

      if (!!show_gcp_usage) {
        const { data: gcpUsage } = await getGcpUsage(invoiceId)
        const gcpInvoiceRange = gcpUsage?.filter(
          item =>
            moment(item.day).isSameOrAfter(moment(data.issue_date)) &&
            moment(item.day).isSameOrBefore(moment(data.due_date))
        )

        // Calculate total cost
        const totalCost = gcpInvoiceRange?.reduce((acc, obj) => acc + obj.cost, 0)
        gcpInvoice =
          !!show_gcp_usage && gcpInvoiceRange?.length > 0
            ? [
                {
                  resource_type: gcpInvoiceRange?.[0]?.service || 'GCP Usage',
                  type: '',
                  label: '',
                  day: data.issue_date,
                  description: '',
                  subscription_id: '',
                  discount: [],
                  sku_name: gcpInvoiceRange?.[0]?.service || 'GCP usage',
                  plan: null,
                  sku_id: '',
                  licence_type: '',
                  price_cad: 0,
                  price_usd: 0,
                  seats: 1,
                  prices: currenciesResponse.data?.map(element => ({
                    currencyId: element.id,
                    totalValue: gcpInvoiceRange?.[0]?.currency_id == element.id ? totalCost : 0
                  })),
                  days: [data.issue_date],
                  quantity: 1,
                  intervals: [
                    {
                      from: moment(data.issue_date).format('YYYY-MM-DD'),
                      to: moment(data.due_date).format('YYYY-MM-DD')
                    }
                  ]
                }
              ]
            : []
      }
      if (!!data)
        dispatch(getInvoiceSuccess(data ? { ...data, items: [...data.items, ...extraInvoice, ...gcpInvoice] } : []))
    } catch (error) {
      console.log('-----error-gcp----', error)
      dispatch(setCustomerFailure())
    }
  }

export const getCustomerDailyChanges = filters => async dispatch => {
  try {
    const { customerId, ...rest } = filters
    dispatch(startLoading('GET_CUSTOMER_DAILY_CHANGES'))
    const { dailyChanges, dailyUsages } = await fetchCustomerDailyUsages(customerId, rest)
    const reformattedChanges = dailyChanges?.data?.map(dailyChange => {
      const matchedDailyUsage = dailyUsages?.find(
        dailyUsage =>
          dailyUsage?.day === dailyChange?.day && dailyUsage?.subscription_id === dailyChange?.subscription_id
      )
      return {
        ...dailyChange,
        dailyUsage: matchedDailyUsage ?? null
      }
    })
    const data = {
      ...dailyChanges,
      data: reformattedChanges
    }
    dispatch(getDailyChangesSuccess(data))
  } catch (error) {
    console.log('err----', error)
    dispatch(setErrorMessage('GET_CUSTOMER_DAILY_CHANGES'))
  }
}

export const updateInvoiceFromFreshbooks =
  ({ customerId, invoiceId, freshbooksInvoiceId }) =>
  async dispatch => {
    try {
      dispatch(startLoading('REFRESH_INVOICE'))
      const { data } = await updateInvoiceFreshbooks(
        `?customerId=${customerId}&invoiceId=${invoiceId}&freshbooksInvoiceId=${freshbooksInvoiceId}`
      )
      dispatch(updateInvoiceSuccess(data))
    } catch (error) {
      dispatch(setCustomerFailure())
    }
  }

export const sendInvoiceToFreshbooks =
  (data, customer, currencyId, type, inAdvanceFormattedTable) => async dispatch => {
    try {
      let usageRows = type === 'advance' ? inAdvanceFormattedTable : splitIntervals(data.items)
      //type === 'advance' ? data.items : splitIntervals(data.items)
      // if (type === 'advance') {
      //   const subsUsages = splitArrayBySub([...data?.item])
      //   let formattedSubUsages = []
      //   for (const subUsage of subsUsages) {
      //     const inAdvanceUsage = subUsage?.filter(item => new Date(item.day) >= new Date(data?.issue_date))
      //     const rearUsage = subUsage?.filter(item => new Date(item.day) < new Date(data?.issue_date))
      //     const [singleDailyUsage] = inAdvanceUsage
      //     let matchedSpanUsage = null
      //     rearUsage?.reverse()?.forEach(item => {
      //       let shouldBreak = false
      //       if (!shouldBreak) {
      //         const lastDay = [...item.days]?.pop()
      //         if (matchedSpanUsage?.seats === item?.seats) {
      //           if (moment(lastDay).isSame(moment(matchedSpanUsage?.day).subtract(1, 'day'))) {
      //             matchedSpanUsage = item
      //           }
      //         } else if (
      //           moment(lastDay).isSame(moment(singleDailyUsage?.day).subtract(1, 'day')) &&
      //           item.seats === singleDailyUsage?.seats
      //         ) {
      //           matchedSpanUsage = item
      //         } else shouldBreak = true
      //       }
      //     })
      //     formattedSubUsages = [
      //       ...formattedSubUsages,
      //       regroupUsages(subUsage, matchedSpanUsage?.day || issueDate, currencyId)
      //     ]
      //   }
      //   usageRows = formattedSubUsages?.flat()
      // }
      let notes = `
    Payment Options:
    
    Bank Transfer:
    Account Name: Premier Cloud Inc.
    Bank: CIBC (Canadian Imperial Bank of Commerce)
    Bank Address: 2339 Beacon Ave, Sidney, BC, V8L 1W9, Canada
    Account #: 8806314
    Currency Held: CAD
    Transit #: 01930
    Institution #: 010
    Routing #: CC001001930
    SWIFT Code: CIBCCATT
    
    Check Payment to:
    Premier Cloud Inc.
    504 - 1803 Douglas Street, V8T 5C3
    Victoria, BC, Canada
    
    Credit Card or PayPal:
    Please contact ar@premiercloud.com. A 3% transaction fee will apply.
    
    Questions? Please email ar@premiercloud.com.
    Interest will be charged at a rate of 2% per month on any balance that remains unpaid after 30 days.
    
    GST# 875789216 RT0001
    `
      if (customer?.currency?.iso_code == 'USD')
        notes = `
Payment Options:

Bank Transfer:
Account Name: Premier Cloud Inc.
Bank: CIBC (Canadian Imperial Bank of Commerce)
Bank Address: 2339 Beacon Ave, Sidney, BC, V8L 1W9, Canada
Account #: 0211214
Currency Held: USD
Transit #: 01930
Institution #: 010
Routing #: CC001001930
SWIFT Code: CIBCCATT

Check Payment to:
Premier Cloud Inc.
504 - 1803 Douglas Street, V8T 5C3
Victoria, BC, Canada

Credit Card or PayPal:
Please contact ar@premiercloud.com. A 3% transaction fee will apply.

For payments outside of Canada, include intermediary bank details:
Intermediary Bank Details:
Wells Fargo Bank NA, New York, NY, US
SWIFT CODE: PNBPUS3NNYC
CHIPS ID: 0509
FEDWIRE/ABA: 026005092

Questions? Please email ar@premiercloud.com.
Interest will be charged at a rate of 2% per month on any balance that remains unpaid after 30 days.

GST# 875789216 RT0001
`

      if (customer?.currency?.iso_code == 'EUR')
        notes = `
Payment Options:

Bank Transfer:
Account Name: Premier Cloud Inc.
IBAN: BE97 9050 7247 2949
Swift/BIC: TRWIBEB1XXX
Bank: Wise
Bank Address: Wise Rue du Trône 100, 3rd floor Brussels 1050 Belgium

Check Payment to:
Premier Cloud Inc.
504 - 1803 Douglas Street, V8T 5C3
Victoria, BC, Canada

Credit Card or PayPal:
Please contact ar@premiercloud.com. A 3% transaction fee will apply.

Questions? Please email ar@premiercloud.com.
Interest will be charged at a rate of 2% per month on any balance that remains unpaid after 30 days.
`

      if (customer?.currency?.iso_code == 'GBP')
        notes = `
Payment Options:

Bank Transfer:
Account Name: Premier Cloud Inc.
Account #: 49820682
IBAN: GB42 TRWI 2314 7049 8206 82
UK Sort Code: 23-14-70
Swift/BIC: TRWIGB2LXXX
Bank: Wise Payments Limited
Bank Address: 56 Shoreditch High Street London E1 6JJ United Kingdom

Check Payment to:
Premier Cloud Inc.
504 - 1803 Douglas Street, V8T 5C3
Victoria, BC, Canada

Credit Card or PayPal:
Please contact ar@premiercloud.com. A 3% transaction fee will apply.

Questions? Please email ar@premiercloud.com.
Interest will be charged at a rate of 2% per month on any balance that remains unpaid after 30 days.
`

      // dispatch(startLoading('SEND_INVOICE'))
      let freshbooksInvoiceObject = {
        email: 'api@premiercloud.com',
        customerId: parseInt(customer.freshbooksId),
        invoiceId: data.id,
        customer_id: data.customer_id,
        createDate: new Date(),
        discountValue: null, //discount_value
        discountDescription: null, //discount_description
        ...(!!customer.dueDate?.value && { dueOffsetDays: parseInt(customer.dueDate?.value) }),
        notes,
        lines: []
      }
      if (!!customer.paymentMethod?.value) {
        const totalTax = customer?.taxes?.reduce((acc, { tax: { value } }) => acc + parseFloat(value), 0) || 0
        const taxedAmount =
          data.items?.reduce(
            (acc, { prices }) => acc + (prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0),
            0
          ) *
          (parseFloat(totalTax) / 100)
        const transactionAmount =
          (data.items?.reduce(
            (acc, { prices }) => acc + (prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0),
            0
          ) +
            parseFloat(taxedAmount)) *
          (parseFloat(customer?.paymentMethod?.value) / 100)
        const paymentMethodLine = {
          unitCost: {
            code: customer?.currency?.iso_code ?? 'CAD',
            amount: parseFloat(transactionAmount).toFixed(2)
          },
          name: customer.paymentMethod.value + '% credit card fees',
          description: customer.paymentMethod.value + '% credit card fees',
          qty: 1
        }

        freshbooksInvoiceObject = {
          ...freshbooksInvoiceObject,
          lines: [...freshbooksInvoiceObject.lines, ...(transactionAmount ? [paymentMethodLine] : [])]
        }
      }
      usageRows?.map((item, index) => {
        const name = item.sku_name.includes('Google Workspace')
          ? 'Google Workspace Licence -' + item.sku_name.replace('Google Workspace', '')
          : item.sku_name

        const domain =
          customer?.googleIds?.find(el => el.subscriptions?.some(sub => sub.subscriptionId === item.subscription_id))
            ?.domain ?? customer?.domain
        //const discount = item.discount?.length > 0 ? formatDiscountDate : ''
        const amount = calculateSingleRow(item.prices, item.total, currencyId, type)
        let lineObject = {
          unitCost: {
            code: customer?.currency?.iso_code ?? 'CAD',
            amount: amount
          },
          name: `${name}`,
          description: `Domain: ${domain}\n${coverPeriod(item.intervals, type, item.type) || ''}${
            item.discount?.length > 0
              ? `\n${formatDiscountDate(customer, item.discount?.[0], item.sku_id, item.licence_type, item.discount)}`
              : ''
          }`,
          qty: type === 'advance' ? item.totalSeats : item.seats // seats
        }
        lineObject.unitCost.amount = (lineObject.unitCost.amount / lineObject.qty).toFixed(3)
        customer?.taxes?.map((tax, index) => {
          // applying taxes from customer tax
          const propertyName = `taxName${index + 1}`
          const propertyValue = `taxAmount${index + 1}`
          const taxData = {
            [propertyName]: tax.tax.name,
            [propertyValue]: tax.tax.value
          }
          lineObject = { ...lineObject, ...taxData }
        })

        freshbooksInvoiceObject = {
          ...freshbooksInvoiceObject,
          lines: [...(lineObject.qty && parseFloat(lineObject.unitCost.amount) > 0 ? [lineObject] : []), ...freshbooksInvoiceObject.lines]
        }
      })
      const response = await regenrateInvoice(freshbooksInvoiceObject)
      dispatch(startLoading(null))
      dispatch(sendInvoiceSuccess(response))
    } catch (error) {
      console.log('-----error----', error)
      dispatch(setErrorMessage('SEND_INVOICE'))
    }
  }

export const sendInvoiceByEmailFreshbooks =
  ({ contacts, freshbooksInvoiceId }) =>
  async dispatch => {
    try {
      dispatch(startLoading('SEND_INVOICE_BY_EMAIL'))
      // Use Promise.all to send the invoice to all emails concurrently
      const sendPromises = contacts.map(item =>
        sendInvoiceByEmailFreshbooksApi({ email: item.email, freshbooksInvoiceId })
      )

      // Wait for all promises to resolve
      await Promise.all(sendPromises)
      dispatch(sendInvoiceByEmailSuccess())
    } catch (error) {
      console.log('---errr--', error)
      dispatch(setCustomerFailure())
    }
  }

export const generateInvoice =
  ({ invoice, type }) =>
  async dispatch => {
    try {
      dispatch(startLoading('GENERATE_INVOICE'))
      Promise.all(
        invoice.customers?.map(async customer => {
          try {
            const response = await generateInvoiceApi(
              `?from=${invoice.startDate}&to=${invoice.endDate}&type=${type}&customerId=${customer}`
            )

            dispatch(generateInvoiceSuccess(response.data))
          } catch (error) {
            if ((error?.response?.data?.msg).includes('daily usage')) dispatch(setErrorMessage('DAILY_USAGE'))
            else if ((error?.response?.data?.msg).includes('advance')) dispatch(setErrorMessage('ADVANCE_INVOICE'))
            else dispatch(setErrorMessage('GENERATE_INVOICE'))
          }
        })
      )
    } catch (error) {
      dispatch(setErrorMessage('GENERATE_INVOICE'))
    }
  }

export const deleteInvoice = (customerId, invoiceId, dueDate) => async dispatch => {
  try {
    dispatch(startLoading('REMOVE_INVOICE'))
    const response = await getInvoice(
      `?customerId=${customerId}&invoiceId=${invoiceId}&action=delete&due_date=${dueDate}`
    )
    dispatch(removeInvoiceSuccess(invoiceId))
  } catch (error) {
    dispatch(setErrorMessage('REMOVE_INVOICE'))
  }
}

export const synchronizeCustomersList =
  ({ limit, page, field }) =>
  async dispatch => {
    try {
      dispatch(startLoading('SYNCHRONIZE_CUSTOMERS'))
      await synchronizeCustomers()
      const response = await getOnboardingCustomers({ limit, page, status: 'pending', field })
      if (response?.data) {
        const { data } = response
        dispatch(getCustomersOnboardingSuccess(data))
      }
    } catch (error) {
      dispatch(setErrorMessage('SYNCHRONIZE_CUSTOMERS'))
    }
  }

export const addNonGoogleSkuSubscription =
  (subscription, dailyUsage, dailyChange, navigationHandler) => async dispatch => {
    try {
      dispatch(startLoading('ADD_NON_GOOGLE_SUBSCRIPTION'))
      const subscriptionResponse = await createNonGoogleSkuSubscription(subscription)
      const dailyUsageResponse = await createDailyUsage(dailyUsage)
      dispatch(startLoading(null))
      navigationHandler()
    } catch (error) {
      dispatch(setErrorMessage('ADD_NON_GOOGLE_SUBSCRIPTION'))
    }
  }

export const updateNonGoogleSkuSubscription = (subscription, dailyChange) => async dispatch => {
  try {
    const { id, ...rest } = subscription
    dispatch(startLoading('UPDATE_SUBSCRIPTION'))
    const subData = await updateNonGoogleSkuSubscriptionApi(id, rest)
    const response = await createDailyChange(dailyChange)
    dispatch(updateSubscription(subData))
  } catch (error) {
    dispatch(setErrorMessage('UPDATE_SUBSCRIPTION'))
  }
}

export const addCustomerInvoiceTemplate = (data, callBack) => async dispatch => {
  try {
    dispatch(startLoading('ADD_INVOICE_TEMPLATE'))
    const res = await addInvoiceTemplate(data)
    dispatch(startLoading(null))
    callBack()
  } catch (error) {
    console.log('---err', error)
    dispatch(setErrorMessage('ADD_INVOICE_TEMPLATE'))
  }
}

export const updateCustomerInvoiceTemplate = (id, data, callBack) => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_INVOICE_TEMPLATE'))
    const res = await updateInvoiceTemplate(id, data)
    dispatch(startLoading(null))
    callBack()
  } catch (error) {
    console.log('---err', error)
    dispatch(setErrorMessage('UPDATE_INVOICE_TEMPLATE'))
  }
}

export const deleteCustomerInvoiceTemplate = (id, callBack) => async dispatch => {
  try {
    dispatch(startLoading('DELETE_INVOICE_TEMPLATE'))
    const res = await deleteInvoiceTemplate(id)
    dispatch(startLoading(null))
    callBack()
  } catch (error) {
    console.log('---err', error)
    dispatch(setErrorMessage('DELETE_INVOICE_TEMPLATE'))
  }
}

export const getCustomerInvoiceTemplates = customerId => async dispatch => {
  try {
    dispatch(startLoading('GET_INVOICE_TEMPLATES'))
    const { data } = await getInvoiceTemplates(customerId)
    dispatch(startLoading(null))
    dispatch(getInvoiceTemplatesSuccess(data))
  } catch (error) {
    console.log('---err', error)
    dispatch(setErrorMessage('GET_INVOICE_TEMPLATES'))
  }
}

export const persistSearchFilters = filters => async dispatch => {
  dispatch(persistSearchingBilling(filters))
}

export const clearPersistedSearchFilters = filters => async dispatch => {
  dispatch(persistSearchingBilling(filters))
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}

export const cleanCustomerInformation = () => dispatch => {
  dispatch(setCustomerSuccess({}))
}
