// ** React Imports
import React from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { customersSelector } from 'store/customers'
import { currencySelector } from 'store/metaData/currency'

// ** 3rd party libraries
import moment from 'moment'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Typography from '@mui/material/Typography'

// ** Custom Components
import TransactionsTableRow from './TransactionTableRow'

// Styled Divider component
const StyledTable = styled(Table)(({ theme }) => ({
  marginTop: '1rem',
  borderTop: `0.5px solid ${theme.palette.grey[300]}`,
  '& .MuiTableCell-head': {
    textTransform: 'capitalize',
    fontWeight: 600,
    color: 'text.secondary'
  },
  '& .MuiTableCell-root': {
    fontSize: '0.825rem'
  }
}))

const splitArrayBySub = inputArray => {
  const groupedArrays = inputArray.reduce((result, obj) => {
    // Check if there is an array for the current subscription_id, if not, create one
    if (!result[obj.subscription_id]) {
      result[obj.subscription_id] = []
    }

    // Push the object to the corresponding subscription_id array
    result[obj.subscription_id].push(obj)

    return result
  }, {})

  // Convert the groupedArrays object to an array of arrays
  return Object.values(groupedArrays)
}

const regroupUsages = (invoiceDetails, issueDate, currencyId) => {
  // Result is to have 2 rows :
  // 1st presents the unbilled old period which is before this invoice issue date
  // 2nd presents the currenct period of the generated invoice
  const tableRows = []
  for (const item of invoiceDetails) {
    // Concatinating old usages together which is before this advance invoice issue date.
    if (new Date(issueDate) > new Date(item.day)) {
      const total = item.prices?.find(item => item.currencyId === currencyId)?.totalValue || 0
      const totalSeats = item?.seats
      tableRows.push({ ...item, total, totalSeats })
    } else {
      // Reaching the current invoice issue date we concat all rows together
      if (
        tableRows.some(el => el.subscription_id === item.subscription_id && new Date(issueDate) <= new Date(el.day))
      ) {
        const row = tableRows?.find(
          el => el.subscription_id === item.subscription_id && new Date(issueDate) <= new Date(el.day)
        ) // get existing row that will be updated

        const index = tableRows?.findIndex(
          el => el.subscription_id === item.subscription_id && new Date(issueDate) <= new Date(el.day)
        ) // index of the existing row
        const total = (item.prices?.find(item => item.currencyId === currencyId)?.totalValue || 0) + (row?.total || 0)
        tableRows[index].days = [...row?.days, ...item?.days]
        tableRows[index].intervals = [...item?.intervals, ...row?.intervals]
        tableRows[index].prices = [...item?.prices, ...row?.prices]
        tableRows[index].total = total
      } else {
        const total = item.prices?.find(item => item.currencyId === currencyId)?.totalValue || 0
        const totalSeats = item?.seats
        tableRows.push({ ...item, total, totalSeats })
      }
    }
  }
  return tableRows
}

const splitIntervals = inputArray => {
  const resultArray = []

  inputArray.forEach(item => {
    if (item.intervals?.length > 1) {
      item.intervals.forEach(interval => {
        const from = moment(interval.from)
        const to = moment(interval.to)
        const newItem = {
          ...item,
          day: interval.from,
          days: item.days?.filter(date => {
            const currentDate = moment(date)
            return currentDate.isBetween(from, to, null, '[]')
          }),
          intervals: [interval]
        }
        resultArray.push(newItem)
      })
    } else {
      resultArray.push(item)
    }
  })

  // Sort Array by sku name & day at the same time
  resultArray.sort((a, b) => {
    // Compare sku_name
    const nameComparison = a.sku_name?.localeCompare(b.sku_name)

    // If sku_name is the same, compare by day
    if (nameComparison === 0) {
      return a.intervals[0]?.from?.localeCompare(b.intervals[0]?.from)
    }

    return nameComparison
  })
  return resultArray
}

const TransactionsTable = props => {
  // ** Props
  const { type, issueDate, endDate, invoiceDetails, setInAdvanceFormattedTable } = props

  // ** Selectors
  const { customerInformation } = useSelector(customersSelector)
  const { currenciesData } = useSelector(currencySelector)

  // ** Variables
  let rows = splitIntervals(invoiceDetails)?.filter(item => !!item.seats) || []

  // ** Constants
  const startDateObj = moment(issueDate)
  const endDateObj = moment(endDate)

  let taxedAmount = 0
  let transactionAmount = 0
  let subtotalAmount = 0
  const currencyId =
    customerInformation?.currency?.id || currenciesData?.find(item => item.iso_code === 'CAD')?.id || null

  // ** Functions

  // ** Initialization reformatted array for in advance case
  if (type === 'advance') {
    // Calculate the difference in months between start_date and end_date
    const diffInMonths = endDateObj.diff(startDateObj, 'months')

    // Split usage data by subscriptions
    const subsUsages = splitArrayBySub([...invoiceDetails])
    let formattedSubUsages = []

    // Check if the difference is greater than 1 month
    if (diffInMonths > 1) {
      // Proceed with regrouping the usage data
      for (const subUsage of subsUsages) {
        const inAdvanceUsage = subUsage?.filter(item => new Date(item.day) >= new Date(issueDate))
        const rearUsage = subUsage?.filter(item => new Date(item.day) < new Date(issueDate))
        const [singleDailyUsage] = inAdvanceUsage
        let matchedSpanUsage = null

        rearUsage?.reverse()?.forEach(item => {
          let shouldBreak = false
          if (!shouldBreak) {
            const lastDay = [...item.days]?.pop()
            if (matchedSpanUsage?.seats === item?.seats) {
              if (moment(lastDay).isSame(moment(matchedSpanUsage?.day).subtract(1, 'day'))) {
                matchedSpanUsage = item
              }
            } else if (
              moment(lastDay).isSame(moment(singleDailyUsage?.day).subtract(1, 'day')) &&
              item.seats === singleDailyUsage?.seats
            ) {
              matchedSpanUsage = item
            } else {
              shouldBreak = true
            }
          }
        })

        formattedSubUsages = [
          ...formattedSubUsages,
          regroupUsages(subUsage, matchedSpanUsage?.day || issueDate, currencyId)
        ]
      }

      // Set the result to your in-advance table
      setInAdvanceFormattedTable(formattedSubUsages?.flat() || [])
      rows = formattedSubUsages?.flat()
    } else {
      // If the difference is 1 month or less, no regrouping is needed
      // Still calculate total and totalSeats to ensure consistency
      formattedSubUsages = subsUsages.map(subUsage =>
        subUsage.map(item => {
          const total = item.prices?.find(p => p.currencyId === currencyId)?.totalValue || 0
          return { ...item, total, totalSeats: item.seats }
        })
      )

      // Set the result to your in-advance table without regrouping
      setInAdvanceFormattedTable(formattedSubUsages?.flat() || [])
      rows = formattedSubUsages?.flat()
    }
  } else {
    // If not 'advance' type, set the table to an empty array or handle accordingly
    setInAdvanceFormattedTable([])
  }

  // Calculate the price from taxes fees
  const taxedPrice = () => {
    const totalTax = customerInformation?.taxes?.reduce((acc, { tax: { value } }) => acc + parseFloat(value), 0) || 0
    taxedAmount =
      invoiceDetails?.reduce(
        (acc, { prices }) => acc + (prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0),
        0
      ) *
      (parseFloat(totalTax) / 100)
    return `$${taxedAmount.toFixed(2)}`
  }

  // Calculate total taxes percent
  const calculateTotalTaxesPercent = () => {
    const taxesName = customerInformation?.taxes?.map(el => el?.tax?.name)?.join(' & ')
    const sumValues = customerInformation?.taxes
      ?.reduce((acc, { tax: { value } }) => acc + parseFloat(value), 0)
      ?.toFixed(0)
    return taxesName + ' (' + sumValues + '%)'
  }

  // Calculate the price from payment method fees
  const paymentMethodFees = () => {
    // ** Don't apply tax for monthly cons logic..
    // invoiceDetails?.reduce(
    //   (acc, element) =>
    //     acc +
    //     (!element.resource_type
    //       ? element.prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0
    //       : 0) *
    //       (element.quantity || 1),
    //   0
    // )
    taxedPrice()
    transactionAmount =
      (invoiceDetails?.reduce(
        (acc, { prices }) => acc + (prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0),
        0
      ) +
        parseFloat(taxedAmount)) *
      (parseFloat(customerInformation?.paymentMethod?.value) / 100)

    return `$${transactionAmount.toFixed(2)}`
  }

  // Calculte rows balance
  const calculateRowsBalance = () => {
    subtotalAmount = invoiceDetails
      ?.reduce((acc, { prices }) => acc + (prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0), 0)
      ?.toFixed(2)
    return `$${subtotalAmount}`
  }

  // Calculate ending balance
  const calculateEndingBalance = () => {
    const total = (parseFloat(subtotalAmount) + parseFloat(transactionAmount) + parseFloat(taxedAmount)).toFixed(2)
    if (customerInformation?.currency?.iso_code === 'EUR') {
      return `€ ${total}`
    }
    return `$${total}`
  }

  return (
    <TableContainer component={Paper}>
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align='right'>Quantity</TableCell>
            <TableCell align='right'>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map(row => (
            <TransactionsTableRow key={row.id} row={row} type={type} />
          ))}
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell align='right' sx={{ whiteSpace: 'nowrap' }}>
              <Typography sx={{ color: 'common.black', fontSize: '0.875rem', my: 2 }}>
                Subtotal ({customerInformation?.currency?.iso_code ?? 'CAD'})&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                {calculateRowsBalance()}
              </Typography>
              {customerInformation?.taxes?.length > 0 && (
                <Typography sx={{ color: 'common.black', fontSize: '0.875rem', my: 2 }}>
                  {calculateTotalTaxesPercent()}
                  &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;{taxedPrice()}
                </Typography>
              )}
              {customerInformation?.paymentMethod?.value > 0 && (
                <Typography sx={{ color: 'common.black', fontSize: '0.875rem', my: 2 }}>
                  Transactions fees ({customerInformation?.paymentMethod?.value}%)&nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                  &nbsp;{paymentMethodFees()}
                </Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell align='right'>
              <Typography>Total &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;{calculateEndingBalance()}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    </TableContainer>
  )
}

export default React.memo(TransactionsTable)
