// ** React Imports
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import { Grid, Button, CircularProgress, useMediaQuery } from '@mui/material'

// ** Icons Imports
import PlusIcon from 'mdi-material-ui/Plus'
import ExportIcon from 'mdi-material-ui/TrayArrowDown'
import { exportSkusToSheet } from 'configs/axios/api_helper'

// Styled Card component
const CustomButton = styled(Button)(({ theme }) => ({
  mx: 1,
  borderRadius: '5px !important',
  fontWeight: 600,
  color: 'common.white',
  fontSize: '0.825rem'
}))

const AddNewSub = () => {
  // ** Hooks
  const navigate = useNavigate()
  const xsmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

  // ** State
  const [loading, setLoading] = useState(false)

  // ** Fucntions
  const redirectHandler = () => navigate('/subscriptions/new-subscription')

  const exportSkusHandler = async () => {
    setLoading(true)
    try {
      // Make an API call to fetch data
      const response = await exportSkusToSheet()
      window.open(response.data.spreadsheetUrl, '_blank')
      setLoading(false)
    } catch (error) {
      console.error('Error fetching or exporting data:', error)
      setLoading(false)
    }
  }

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Grid item xs={10} sm={6} sx={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: 5 }}>
      <Button
        variant='text'
        sx={{ fontWeight: 600, fontSize: '0.825rem', whiteSpace: 'nowrap' }}
        startIcon={<ExportIcon />}
        onClick={exportSkusHandler}
        disabled={loading}
        endIcon={loading && <CircularProgress size='1rem' sx={{ color: theme => theme.palette.secondary.main }} />}
      >
        {!xsmall && 'Export SKUs'}
      </Button>
      <CustomButton onClick={redirectHandler} variant='contained' startIcon={<PlusIcon />}>
        New Subscription
      </CustomButton>
    </Grid>
  )
}

export default AddNewSub
